import { Ref, ref, shallowRef } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import createHttpClient from '@/api/httpClient'
import { AxiosResponse } from 'axios'
import {
  AgendaResponse,
  AgendaTimeSlot,
  TimeSlotInformationData,
} from '@/api/timeslot/timeslot'
import { InitTimeSlotParams, TimeSlotEvent } from './timeSlotEvent'
import {
  CalendarID,
  calendarApp,
  colorAppointmentReasons,
  profileColors,
  scrollController,
} from '@/components/calendar/calendarApp'
import { useUserStore } from '../user/userStore'
import { useAddressStore } from '../address/addressStore'
import MessageService from '@/components/feedback/message/messageService'
import { ProfileInformationData } from '@/api/profile/profile.d'
import { AddressInformationData } from '@/api/account/address'
import { useVacationEventStore } from '../vacation/vacationEventStore'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { useDialogTimeSlotStore } from './dialogTimeSlotStore'
import {
  VerifyTimeSlotParams,
  VerifyTimeSlotResponse,
} from '@/api/timeslot/verify'
import { ApiResponse } from '@/api/api'
import ConfirmDialogService from '@/components/feedback/confirmDialog/confirmDialogService'
import {
  ValidateTimeSlotParams,
  ValidateTimeSlotResponse,
} from '@/api/timeslot/validate'
import { useAppointmentReasonStore } from '../appointment-reason/appointementReasonStore'
import EventBus from '@/utils/eventBus'
import { calendarsUpdaterPlugin } from '@/components/calendar/calendarState'
import i18n from '@/plugins/i18n'
import { FormattedAppointmentReasonInformationData } from '@/api/appointment-reason/appointment-reason'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useDashboardStatus } from '../dashboard-status/dashboardStatus'
import { useProfileStore } from '@/store/profile/profileStore'

interface Timeslot {
  profile: string
  address: string
  start: string
  end: string
  repeat: boolean
  days: number[]
}

dayjs.extend(utc)
dayjs.extend(timezone)

const httpClient = createHttpClient()
const { t } = i18n.global

export const useTimeSlotStore = defineStore('timeslot', () => {
  const timeSlots: Ref<TimeSlotEvent[]> = shallowRef([])
  const dashboardState = useDashboardStatus()
  const dialogStore = useDialogTimeSlotStore()
  const addressStore = useAddressStore()

  const fillTimeSlots = async (
    profileIds: string[],
    addressIds: string[],
    appointmentReasonIds: string[],
    ownerId,
    start,
    end,
  ) => {
    try {
      const currentUrl = window.location.href

      const timeSlotResponse: AxiosResponse<AgendaResponse> =
        await httpClient.get(
          `/agenda/agenda?profiles=${profileIds.join(',')}&addresses=${addressIds.join(',')}` +
            `&appointmentReason=${appointmentReasonIds.join(',')}` +
            `&start=${start}&end=${end}` +
            `&owner=${ownerId ? ownerId : ''}` +
            `&currentUrl=${encodeURIComponent(currentUrl)}`,
        )
      if (timeSlotResponse.status !== 200) {
        throw Error(JSON.stringify(timeSlotResponse))
      }

      timeSlots.value = formatResponse(timeSlotResponse.data.timeslots)
    } catch (error) {
      console.error(error)
    }
  }

  const formatResponse = (timeSlots: AgendaTimeSlot[]): TimeSlotEvent[] => {
    const appointementReasonStore = useAppointmentReasonStore()
    return [
      ...timeSlots.map((timeSlot: AgendaTimeSlot): TimeSlotEvent => {
        const originalTimeSlot = timeSlot.timeslot
        const { start, end } = timeSlot

        let acceptRemote: 'in-person' | 'remote' | 'both' = 'in-person'
        if (originalTimeSlot.presential && originalTimeSlot.remote) {
          acceptRemote = 'both'
        } else if (originalTimeSlot.presential) {
          acceptRemote = 'in-person'
        } else if (originalTimeSlot.remote) {
          acceptRemote = 'remote'
        }

        let weekRepeat: 'yes' | 'no' = 'yes'
        if (!originalTimeSlot.weekRepeat) {
          weekRepeat = 'no'
        }

        const profileId =
          originalTimeSlot.agenda.agendaType == 'old'
            ? originalTimeSlot.agenda.profile.id
            : null
        const addressId =
          originalTimeSlot.agenda.agendaType == 'old'
            ? originalTimeSlot.agenda.address.id
            : originalTimeSlot.address.id

        const timeslotTimeZone = originalTimeSlot.timezone
          ? originalTimeSlot.timezone
          : dayjs.tz.guess()
        return {
          id: timeSlot.id,
          type: 'TimeSlotEvent',
          start: dayjs(start)
            // .tz(start,timeslotTimeZone)
            .utc()
            .tz(dayjs.tz.guess())
            .format('YYYY-MM-DD HH:mm'),
          end: dayjs(end)
            // tz(end,timeslotTimeZone).
            .utc()
            .tz(dayjs.tz.guess())
            .format('YYYY-MM-DD HH:mm'),
          dateEnd: originalTimeSlot.dateEnd
            ? dayjs
                .utc(originalTimeSlot.dateEnd)
                .tz(dayjs.tz.guess())
                .subtract(1, 'day')
                .format('YYYY-MM-DD')
            : null,
          title: (() => {
            const fullTitle = originalTimeSlot.appointmentReasons
              .map(reason => reason.label)
              .join(', ')

            return fullTitle.length > 40
              ? fullTitle.slice(0, 40) + '...'
              : fullTitle
          })(),
          description: originalTimeSlot.appointmentReasons
            .map(ar => `${ar.label} : ${ar.description}`)
            .join('\n\n'),
          location: `Remote : ${originalTimeSlot.remote}, Presential : ${originalTimeSlot.presential}`,
          calendarId: String(CalendarID.PROFILE_1),
          appointmentReasons: originalTimeSlot.appointmentReasons.map(
            (reason): FormattedAppointmentReasonInformationData => ({
              ...reason,
              remote: reason.remote ? 'yes' : 'no',
              profile: { id: profileId },
            }),
          ),
          profiles: originalTimeSlot.profiles,
          profileId:
            originalTimeSlot.agenda.agendaType == 'old'
              ? originalTimeSlot.agenda.profile.id
              : null,
          addressId:
            originalTimeSlot.agenda.agendaType == 'old'
              ? originalTimeSlot.agenda.address.id
              : originalTimeSlot.address.id,
          acceptRemote,
          days: originalTimeSlot.days.sort(),
          weekRepeat,
          weekRepetition: originalTimeSlot.weekRepetition
            ? originalTimeSlot.weekRepetition
            : 1,
          timeSlotId: originalTimeSlot.id,
          color: originalTimeSlot.color ? originalTimeSlot.color : '#4467c5',
          profileColor:
            profileColors[
              profileStore.profiles.findIndex(p => p.id == profileId)
            ],
          appointmentReasonColor:
            colorAppointmentReasons[
              appointementReasonStore.appointmentReasons.findIndex(
                a => a.id == originalTimeSlot.appointmentReasons[0].id,
              )
            ],
        }
      }),
    ]
  }

  const getAddressIds: (addressItems: AddressInformationData[]) => string[] = (
    addressItems: AddressInformationData[],
  ) => addressItems.map(a => a.id)

  const getProfileIds: (profileItems: ProfileInformationData[]) => string[] = (
    profileItems: ProfileInformationData[],
  ) => profileItems.map(a => a.id)
  const errors = ref('')
  const userStore = useUserStore()
  const profileStore = useProfileStore()
  const vacationStore = useVacationEventStore()
  const initTimeSlots = async (
    {
      profileIds,
      addressIds,
      appointmentReasonIds,
      ownerId,
      start,
      end,
    }: InitTimeSlotParams = {
      profileIds: null,
      addressIds: null,
      appointmentReasonIds: null,
      ownerId: null,
      start: null,
      end: null,
    },
  ) => {
    try {
      LoadingBackdropService.start()
      calendarApp.value.events.set([])
      await vacationStore.initVacations()

      // await userStore.initAddresses()
      await addressStore.initAddresses()
      if (!addressIds) {
        addressIds = getAddressIds(addressStore.addresses)
      }

      // await userStore.initProfiles()
      await profileStore.initProfiles()
      if (!profileIds) {
        profileIds = getProfileIds(profileStore.profiles)
      }

      const appointReasonStore = useAppointmentReasonStore()
      await appointReasonStore.fillAppointmentReasons()
      if (!appointmentReasonIds) {
        appointmentReasonIds = appointReasonStore.appointmentReasons.map(
          ar => ar.id,
        )
      }

      if (start === null) {
        start = dayjs(
          calendarsUpdaterPlugin.$app.calendarState.range.value.start,
          'YYYY-MM-DD HH:mm',
        ).toISOString()
      }

      if (end === null) {
        end = dayjs(
          calendarsUpdaterPlugin.$app.calendarState.range.value.end,
          'YYYY-MM-DD HH:mm',
        )
          .add(1, 'minute')
          .toISOString()
      }

      await fillTimeSlots(
        profileIds,
        addressIds,
        appointmentReasonIds,
        ownerId,
        start,
        end,
      )
      const events = [...timeSlots.value, ...vacationStore.vacations]
      calendarApp.value.events.set(events)
      scrollToFirstHour()
    } catch (error) {
      console.error(error)
      MessageService.error(t('dashboard.time-slot.errorGetTimeSlot'))
    }
    LoadingBackdropService.stop()
  }

  const scrollToFirstHour = () => {
    function trouverDateHeureLaPlusTot(dates) {
      // Initialiser le tableau des objets dayjs
      const datesConverties = dates.map(date => dayjs(date, 'YYYY-MM-DD HH:mm'))

      // Trouver la date avec l'heure la plus tôt
      const dateMin = datesConverties.reduce((a, b) => {
        return a.hour() < b.hour() ||
          (a.hour() === b.hour() && a.minute() < b.minute())
          ? a
          : b
      })

      return dateMin.format('YYYY-MM-DD HH:mm')
    }

    if (!timeSlots.value.length) return

    const dateAvecHeureLaPlusTot = trouverDateHeureLaPlusTot(
      timeSlots.value.map(t => t.start),
    )

    scrollController.scrollTo(dateAvecHeureLaPlusTot.split(' ')[1])
  }

  const loading = ref(false)
  const handleCreateOrUpdateTimeSlot = async (
    verifyPayload: VerifyTimeSlotParams,
    change: ChangeParams,
  ) => {
    // console.log(verifyPayload)

    const NO_VERIFY = true // old behaviour
    try {
      loading.value = true
      let response: ApiResponse<VerifyTimeSlotResponse>
      if (!NO_VERIFY) {
        if (!dialogStore.isEditing) {
          response = await httpClient.post(`/timeslot/verify`, verifyPayload, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
        } else {
          if (verifyPayload.weekRepeat) {
            // verifyPayload.date = null
          }
          response = await httpClient.put(
            `/timeslot/${dialogStore.timeSlotId}/verify${verifyPayload.weekRepeat ? '?change=' + change : ''}`,
            verifyPayload,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
        }
        if (response.status == 422) {
          MessageService.error(response.data.message)
        } else if (response.status != 200) {
          throw Error(JSON.stringify(response))
        }
      } else if (NO_VERIFY) {
        if (verifyPayload.weekRepeat) {
          // verifyPayload.date = null
        }
      }

      if (dialogStore.isEditing && dialogStore.repeatTimeSlot === 'yes') {
        // Special case for edition with reccuring events
        EventBus.emit('openModalConfirm', {
          mode: 'edition',
          message: NO_VERIFY ? '' : response.data.message,
          messageChangeAll: NO_VERIFY ? '' : response.data.messageChangeAll,
          messageChangeSingle: NO_VERIFY
            ? ''
            : response.data.messageChangeSingle,
          change,
        })
      } else {
        let message
        if (NO_VERIFY) {
          message = dialogStore.isEditing
            ? t('dashboard.time-slot.confirmEditTimeSlotMessage')
            : t('dashboard.time-slot.confirmCreateTimeSlotMessage')
        }
        ConfirmDialogService.confirm({
          title: dialogStore.isEditing
            ? t('dashboard.time-slot.confirmEditTimeSlot')
            : t('dashboard.time-slot.confirmCreateTimeSlot'),
          message: NO_VERIFY ? message : response.data.message,
          optionsConfirm: {
            onCancel() {
              errors.value = ''
            },
            onConfirm() {
              handleConfirmTimeSlot(verifyPayload, change)
            },
          },
          cancelBtnLabel: t('dashboard.time-slot.cancel'),
          confirmBtnLabel: t('dashboard.time-slot.confirm'),
        })
      }
    } catch (err) {
      console.error('error in create or uPdate', err)
      MessageService.error(err.response?.data?.message)
      loading.value = false
    }

    loading.value = false
  }

  type ChangeParams = 'removeAfterDate' | 'single' | 'all'
  const handleConfirm = {
    create: {
      call: async (
        payload: ValidateTimeSlotParams,
        change: ChangeParams = 'single',
      ) => {
        try {
          return await httpClient.post(`/timeslot/validate`, payload, {
            headers: { 'Content-Type': 'application/json' },
          })
        } catch (e) {
          if (e.response.status == 422) {
            MessageService.error(e.response.data.message)
            // EventBus.emit('openConflictTimeslotDialog')
            // errors.value = e.response.data.message
            dialogStore.openConflictModal(e.response.data.timeslot)
          } else {
            MessageService.error(t('dashboard.time-slot.errorCreateTimeSlot'))
          }
        }
      },
      success: () => {
        MessageService.success(t('dashboard.time-slot.successCreateTimeSlot'))
      },
      error: () => {
        // MessageService.error(t('dashboard.time-slot.errorCreateTimeSlot'))
        LoadingBackdropService.stop()
      },
    },
    edit: {
      call: async (
        payload: ValidateTimeSlotParams,
        change: ChangeParams = 'single',
      ) => {
        try {
          // console.log(payload)
          return await httpClient.put(
            `/timeslot/${dialogStore.timeSlotId}/validate?change=${change}`,
            payload,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
        } catch (e) {
          if (e.response.status == 422) {
            MessageService.error(e.response.data.message)
            dialogStore.openConflictModal(e.response.data.timeslot)
          }
          // console.log(e)
        }
      },
      success: () => {
        MessageService.success(t('dashboard.time-slot.successUpdateTimeSlot'))
      },
      error: () => {
        LoadingBackdropService.stop()
      },
    },
  }

  const addTimeSlot = (newTimeSlot: TimeSlotEvent) => {
    // Ajout du nouvel événement dans timeSlots

    timeSlots.value.push(newTimeSlot)
  }

  const updateTimeSlot = (updatedTimeSlot: TimeSlotEvent) => {
    // Recherche de l'événement à modifier dans timeSlots
    const index = timeSlots.value.findIndex(
      slot => slot.id === updatedTimeSlot.id,
    )
    if (index !== -1) {
      // Mise à jour de l'événement existant
      timeSlots.value[index] = updatedTimeSlot
    }
  }

  const deleteTimeSlot = (timeSlotId: string) => {
    // Suppression de l'événement dans timeSlots
    timeSlots.value = timeSlots.value.filter(slot => slot.id !== timeSlotId)
  }

  const formatValidateTimeSlotResponse = (
    response: ValidateTimeSlotResponse,
  ): TimeSlotEvent => {
    const {
      id,
      days,
      weekRepeat,
      date,
      startTime,
      endTime,
      remote,
      appointmentReasons,
      presential,
      color,
    } = response

    return {
      id: id,
      type: 'TimeSlotEvent', // ou une logique pour définir le type
      timeSlotId: id, // Peut-être assigner `id` ou une autre valeur
      start: `${date} ${startTime}`, // Format YYYY-MM-DD HH:mm
      end: `${date} ${endTime}`, // Format YYYY-MM-DD HH:mm
      title: 'New Time Slot', // Remplacer par la logique pour le titre
      description: 'New Time Slot', // Valeur par défaut ou logique pour remplir la description
      location: '', // Valeur par défaut ou logique pour remplir la localisation
      calendarId: '0', // Ajouter la logique pour obtenir l'ID du calendrier si nécessaire
      appointmentReasons: [],
      profileId: '', // Ajouter la logique pour obtenir l'ID du profil si nécessaire
      profiles: [], // Ajouter la logique pour récupérer les profils associés
      addressId: '', // Ajouter la logique pour obtenir l'ID de l'adresse si nécessaire
      acceptRemote: remote ? 'both' : 'in-person', // Adapter la logique selon vos besoins
      weekRepeat: weekRepeat ? 'yes' : 'no',
      profileColor: color, // Utiliser la couleur si disponible
      days: [],
      appointmentReasonColor: color, // Remplacer par une logique appropriée si nécessaire
      isOnVacation: false, // Remplacer par la logique appropriée si nécessaire
      color: color, // Utiliser la couleur si disponible
    }
  }

  // const formatTimeslotResponse = (
  //   response: ValidateTimeSlotResponse,
  // ): AgendaTimeSlot => {
  //   const timeSlotInformationData: TimeSlotInformationData = {
  //     id: response.id,
  //     agenda: {
  //       profile: { id: null }, // Remplacez par la logique appropriée
  //       address: { id: null }, // Remplacez par la logique appropriée
  //       agendaType: null, // Remplacez par la logique appropriée
  //     },
  //     days: [], // Initialisez en fonction de votre logique
  //     weekRepeat: response.weekRepeat,
  //     date: response.date ?? '', // Assurez-vous que ce soit un string
  //     dateEnd: response.date ?? '', // Assurez-vous que ce soit un string
  //     startTime: response.startTime,
  //     endTime: response.endTime,
  //     remote: response.remote,
  //     color: response.color ?? '',
  //     appointmentReasons: [],
  //     profiles: [], // Initialisez selon votre logique
  //     presential: response.presential,
  //     weekRepetition: 0, // Remplacez par la logique appropriée
  //     profile: { id: null }, // Remplacez par la logique appropriée
  //     address: { id: null }, // Remplacez par la logique appropriée
  //   }

  //   return {
  //     id: timeSlotInformationData.id,
  //     start: timeSlotInformationData.startTime,
  //     end: timeSlotInformationData.endTime,
  //     timeslot: timeSlotInformationData,
  //   }
  // }

  const handleConfirmTimeSlot = async (
    validateTimeSlotPayload: ValidateTimeSlotParams,
    change: ChangeParams,
  ) => {
    errors.value = ''
    const methodsConfirm = dialogStore.isEditing
      ? handleConfirm.edit
      : handleConfirm.create

    LoadingBackdropService.start()

    try {
      const response: ApiResponse<ValidateTimeSlotResponse> =
        await methodsConfirm.call(validateTimeSlotPayload, change)
      if (response.status != 200) throw Error(JSON.stringify(response))

      // if (response.status === 200) {
      //   const newOrUpdatedTimeSlot: TimeSlotEvent  = formatValidateTimeSlotResponse(response.data);

      //   if (dialogStore.isEditing) {
      //     // Modification d'un événement existant
      //     updateTimeSlot(newOrUpdatedTimeSlot);
      //   } else {
      //     // Ajout d'un nouvel événement
      //     addTimeSlot(newOrUpdatedTimeSlot);
      //   }

      // }
      MessageService.success(t('dashboard.time-slot.successOperation'))

      methodsConfirm.success()

      // update dashboard status
      dashboardState.fetchDashboardStatus()

      await initTimeSlots()

      // const events = [...timeSlots.value, ...vacationStore.vacations]
      // calendarApp.value.events.set(events)
      // console.log(calendarApp.value.events.getAll())
      if (dialogStore.isDialogTimeSlotOpen) {
        dialogStore.toggleDialogTimeSlot()
      }
    } catch (err) {
      methodsConfirm.error()
      // errors.value = err.response.data.message
      // console.error(err)
      LoadingBackdropService.stop()
    }
    EventBus.emit('closeTimeslotConfirmDialog')
    LoadingBackdropService.stop()
  }

  return {
    timeSlots,
    loading,
    initTimeSlots,
    formatResponse,
    handleCreateOrUpdateTimeSlot,
    handleConfirmTimeSlot,
    errors,
  }
})
