import i18n from '@/plugins/i18n'

const { t } = i18n.global

interface IAddress {
  addressLine: string
  postalCode: string
  city: string
  country: string
  [key: string]: any
}

export function formatAddress(
  address: IAddress,
  remoteWithCity: boolean = false,
) {
  return address.remote
    ? t('practitionerPublicProfile.remoteLabel') +
        (remoteWithCity ? ` (${address.city})` : '')
    : `${address.addressLine}, ${address.postalCode} ${address.city}, ${address.country}`
}
