<template>
  <div class="">
    <h3 class="text-grey">
      {{ $t('dashboard.addresses.description') }}
    </h3>
    <div class="add-lists d-flex flex-column ga-3 mt-5">
      <div class="add-block d-flex flex-column ga-3">
        <div class="add-title">
          <p v-if="addressList.length">
            {{ $t('dashboard.addresses.registeredAddresses') }}
          </p>
          <p v-else-if="!fillAddressesLoading" class="">
            {{ $t('dashboard.addresses.noAddress') }}
          </p>
        </div>
        <div class="d-flex flex-column ga-2">
          <v-slide-x-reverse-transition
            class=""
            v-for="(address, i) in addressList"
            :key="address.addressLine"
          >
            <div
              class="rounded-lg cursor-pointer d-flex align-center justify-space-between bg-white px-2 py-1"
            >
              <p class="add-val">
                {{ truncateText(formatAddress(address, true), 60) }}
              </p>

              <div class="d-flex ga-2 align-center">
                <img
                  class="cursor-pointer"
                  width="24"
                  height="24"
                  src="@/assets/edit.png"
                  @click="switchEditMode(address, i)"
                />

                <v-tooltip
                  v-if="addressList.length === 1"
                  location="top"
                  :text="$t('dashboard.addresses.tooltipMsg')"
                >
                  <template v-slot:activator="{ props }">
                    <CloseIcon
                      class="cursor-pointer"
                      v-bind="props"
                      size="24"
                      v-on="props.on"
                      :disabled="addressList.length == 1"
                    />
                  </template>
                </v-tooltip>

                <CloseIcon
                  class="cursor-pointer"
                  v-else
                  @click="
                    (addressDialog = true),
                      (actionType = 'delete'),
                      (selectedAdressIndex = i)
                  "
                  :disabled="addressList.length == 1"
                />
              </div>
            </div>
          </v-slide-x-reverse-transition>
        </div>
      </div>

      <!-- <v-row v-if="showForm" class="ga-2 justify-end mb-6" no-gutters>
        <v-btn
          :variant="!showForm ? 'flat' : 'outlined'"
          color="secondary"
          width="150"
          size="large"
          @click="handleAddOrCancelBtn(!showForm ? 'add' : 'cancel')"
        >
          {{
            !showForm
              ? $t('dashboard.addresses.add')
              : $t('dashboard.addresses.cancel')
          }}
        </v-btn>
      </v-row> -->

      <v-expand-transition>
        <div v-if="showForm" class="pl-sm-6 d-sm-flex ga-5 w-100 pb-5">
          <div class="w-sm-66">
            <p class="add-title">
              {{
                addressStore.formMode === 'edit'
                  ? `${$t('dashboard.addresses.edit-form-title')}`
                  : `${$t('dashboard.addresses.add-form-title')}`
              }}
            </p>
            <v-form
              ref="form"
              v-model="isValidForm"
              @submit.prevent="submit"
              class="d-flex flex-column ga-4"
            >
              <v-switch
                v-model="onlyRemote"
                :label="$t('dashboard.addresses.useOnlyRemote')"
                color="blue"
                hide-details
              />

              <div>
                <label>{{
                  $t('dashboard.addresses.selectCountry') + '*'
                }}</label>
                <v-autocomplete
                  v-model="country"
                  :items="Object.keys(countries)"
                  :rules="mandatoryRules"
                  clearable
                  hide-details
                  validate-on="lazy"
                  rounded="lg"
                  density="compact"
                />
              </div>

              <div v-if="!no_add_yet">
                <label>{{ $t('dashboard.addresses.address') + '*' }}</label>
                <v-autocomplete
                  rounded="lg"
                  density="compact"
                  v-model="addressLine"
                  :rules="mandatoryRules"
                  :items="autocompleteAddressList"
                  :auto-select-first="false"
                  :custom-filter="() => true"
                  clearable
                  item-title="label"
                  item-value="value"
                  return-object
                  hide-details
                  validate-on="lazy"
                  autocomplete="new-input"
                  @input="onInputAddress"
                  @update:search="autocompleteRequest"
                  @update:model-value="handleSelectAutocomplete($event)"
                >
                  <template v-slot:append-inner="{ isActive }">
                    <v-btn
                      v-show="loadingAddresses && isActive"
                      :loading="loadingAddresses"
                      variant="text"
                      icon="mdi-loading"
                      disabled
                    >
                    </v-btn>
                  </template>
                </v-autocomplete>
              </div>

              <div v-if="no_add_yet">
                <label>{{ $t('dashboard.addresses.street') }}</label>
                <v-text-field
                  rounded="lg"
                  density="compact"
                  v-model="searchPlace"
                  hide-details
                  clearable
                  validate-on="lazy"
                  placeholder="Ex : Paris, rue 223"
                  :loading="streetLoad"
                />
              </div>

              <div class="d-flex ga-4 ga-sm-2" :class="{ 'flex-column': xs }">
                <div class="w-sm-50">
                  <label>{{
                    $t('dashboard.addresses.postalCode') + '*'
                  }}</label>
                  <v-text-field
                    rounded="lg"
                    density="compact"
                    v-model="postalCode"
                    :rules="mandatoryRules"
                    hide-details
                    clearable
                    validate-on="lazy"
                  />
                </div>
                <div class="w-sm-50">
                  <label>{{ $t('dashboard.addresses.city') + '*' }}</label>
                  <v-text-field
                    rounded="lg"
                    density="compact"
                    v-model="city"
                    hide-details
                    :rules="mandatoryRules"
                    clearable
                    validate-on="lazy"
                  />
                </div>
              </div>

              <template v-if="no_add_yet">
                <div class="d-sm-flex ga-2">
                  <div class="w-sm-50 w-100">
                    <label>{{ $t('dashboard.addresses.longitude') }}</label>
                    <v-text-field
                      rounded="lg"
                      density="compact"
                      v-model="longitude"
                      :rules="mandatoryRules"
                      disabled
                      hide-details
                      clearable
                      validate-on="lazy"
                    />
                  </div>

                  <div class="w-sm-50 w-100">
                    <label>{{ $t('dashboard.addresses.latitude') }}</label>
                    <v-text-field
                      rounded="lg"
                      density="compact"
                      v-model="latitude"
                      :rules="mandatoryRules"
                      hide-details
                      clearable
                      validate-on="lazy"
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <label>{{ $t('dashboard.addresses.public_address') }}</label>
                  <v-text-field
                    rounded="lg"
                    density="compact"
                    v-model="addLine"
                    :rules="mandatoryRules"
                    hide-details
                    clearable
                    validate-on="lazy"
                  />
                </div>
              </template>

              <div>
                <label>{{ $t('dashboard.addresses.addtionnalInfo') }}</label>
                <rich-editor
                  v-model:content="additionalInstructions"
                  :key="additionalInstructionsKey"
                  contentType="html"
                  class="rich-text-field"
                  :counter="500"
                >
                </rich-editor>
              </div>

              <v-checkbox
                v-model="no_add_yet"
                hide-details
                color="#757575"
                density="compact"
                :label="$t('dashboard.addresses.no_addr_yet')"
              />
              <p
                v-if="showForm && false"
                class="text-end text-grey font-italic"
              >
                {{ $t('dashboard.addresses.asteriskForMandatoryFields') }}
              </p>

              <div class="d-sm-flex ga-2">
                <v-btn
                  class="w-sm-50 w-100"
                  color="#E0E0E0"
                  @click="handleAddOrCancelBtn('cancel')"
                >
                  <span class="text-gray-80">
                    {{ $t('dashboard.addresses.cancel') }}
                  </span>
                </v-btn>

                <v-btn
                  class="mb-3 mt-2 mt-sm-0 text-uppercase align-self-center w-sm-50 w-100"
                  color="secondary"
                  :loading="addAddressLoading"
                  type="submit"
                >
                  {{
                    addressStore.formMode === 'edit'
                      ? $t('dashboard.addresses.updateAddresses')
                      : $t('dashboard.addresses.validate')
                  }}
                </v-btn>
              </div>
            </v-form>
          </div>
          <div class="w-sm-33 h-auto">
            <MapAddress
              @update:position="updatePosition($event)"
              :latitude="latitude"
              :longitude="longitude"
              :mapcountry="country"
              :searchQuery="searchThePlace"
              :not_yet="no_add_yet"
              @streetLoaading="streetLoading($event)"
            />
          </div>
        </div>
      </v-expand-transition>
    </div>
    <AdressDialog
      :loading="loadingChangeAdd"
      @update:close="addressDialog = false"
      @update:confirm="
        actionType === 'delete'
          ? handleDelete(selectedAdressIndex)
          : handleUpdateAddresses()
      "
      v-if="addressDialog"
      :dialog="addressDialog"
      :type="actionType"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, Ref, watch, onBeforeUnmount } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { VForm } from 'vuetify/lib/components/index.mjs'
import {
  deleteAddress,
  getAddresses,
  updateAddresses,
  checkAppointment,
} from '@/api/account/account'
import {
  AddressInformationData,
  AddressUpdateParams,
} from '@/api/account/address'
import countries from '../../../shared/5-countries-dom-tom-dic.json'
import { GeocodeEarthParams } from '@/api/utils/geocodeearth'
import { formatReceivedAddresses } from './addressUtils'
import FormBlock from '@/components/common/FormBlock.vue'
import { formatAddress } from '@/utils/formatAddress'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import {
  getAlpha2CountryCodeWithDOMTOM,
  getAlpha3CountryCode,
} from '@/utils/getCountryCode'
import LoadingBackdropService from '@/components/feedback/loadingBackdrop/loadingBackdropService'
import { useDashboardStatus } from '@/store/dashboard-status/dashboardStatus'
import { useGoogleMapsStore } from '@/store/google-maps/googleMapsStore'
import { truncateText } from '@/utils/truncate'
import MessageService from '@/components/feedback/message/messageService'
import RichEditor from '@/components/inputs/RichEditor.vue'
import { useAddressStore, FormMode as Mode } from '@/store/address/addressStore'
// import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import AdressDialog from '@/components/dialog/AddressDialog.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
// const dialogStore = useDialogTimeSlotStore()
import MapAddress from '@/components/MapAddress.vue'

const googleMapsStore = useGoogleMapsStore()
const addressDialog = ref(false)
interface AutocompleteAddress {
  label: string
  value: AddressItem
}

interface AddressItem {
  country: string | null
  addressLine: string | null
  postalCode: string | null
  city: string | null
  latitude?: string
  longitude?: string
  additionalInstructions?: string | null
  remote?: boolean
  noAddYet?: boolean
}

interface AddressLine {
  label: string
  value: AddressItem
}
enum FormMode {
  HIDDEN = 'hidden',
  ADD = 'add',
  EDIT = 'edit',
}

const dashboardState = useDashboardStatus()

const $t = useI18n().t

const { xs } = useDisplay()

const mandatoryRules = [
  value => !!value || $t('dashboard.addresses.requiredField'),
]

const addressList: Ref<AddressInformationData[]> = ref([])

const form: Ref<VForm> = ref(null)
// const formMode: Ref<FormMode> = ref(FormMode.HIDDEN)
// const showForm = ref(false)

const formMode = computed(() => {
  return addressStore.formMode
})

const submit = async () => {
  const valid = await form.value.validate()

  console.log('valid', valid)

  if (!valid) return false

  if (formMode.value === Mode.EDIT) {
    confirm(ModalWarningType.EDIT)
    return
  }
  handleUpdateAddresses()
}

const showForm = computed(() => {
  return addressStore.displayAddrForm
})

const onlyRemote = ref<boolean>(false)
const actionType = ref()
const country = ref('')
const addressLine: Ref<AddressLine> = ref({ label: '', value: null })
const postalCode = ref('')
const city = ref('')
const additionalInstructions = ref(null)
const additionalInstructionsKey = ref(0)
const latitude = ref('')
const longitude = ref('')
const no_add_yet = ref<boolean>(false)
const addLine = ref('')
const autocompleteAddressList: Ref<AutocompleteAddress[]> = ref([])
const isValidForm = ref(false)
const selectedAdressIndex = ref(null)

const filteredAddresses = computed(() =>
  addressList.value.filter((a, i) => i != selectedAdressIndex.value),
)

const dialogActionLoading = ref(false)
const setBtnDialogLoading = (isLoading: boolean) => {
  dialogActionLoading.value = isLoading
}

const loadingAddresses = ref(false)

const migrationAddressIndex = ref(0)

enum ModalWarningType {
  DELETE = 'delete',
  EDIT = 'edit',
  NONE = '',
  WARNING = 'warning',
}

const modal = ref(false)

const modalType: Ref<ModalWarningType> = ref(ModalWarningType.NONE)
const addressStore = useAddressStore()

const modalText = computed(() => {
  if (modalType.value == ModalWarningType.EDIT) {
    return $t('dashboard.addresses.alertOnUpdate')
  } else if (modalType.value == ModalWarningType.DELETE) {
    return $t('dashboard.addresses.alertOnDelete', addressList.value.length)
  } else if (modalType.value == ModalWarningType.WARNING) {
    return $t('dashboard.addresses.alertOnWarning')
  }
  return ''
})

const openModal = () => {
  modal.value = true
}

const closeModal = () => {
  modal.value = false
}

const confirm = async (type: ModalWarningType) => {
  if (
    !isValidForm.value &&
    type !== ModalWarningType.DELETE &&
    type !== ModalWarningType.WARNING
  ) {
    return
  }
  modalType.value = type
  // selectedAdressIndex.value = i

  if (ModalWarningType.DELETE === type) {
    const { status, data } = await checkAppointment(
      addressList.value[selectedAdressIndex.value].id,
    )

    let hasPatient = data
    if (hasPatient) openModal()
    else await handleDelete(selectedAdressIndex.value)
  } else {
    addressDialog.value = true
    actionType.value = 'edit'
  }
}

const handleCheckAppointment = async index => {
  closeModal()
  const { status, data } = await checkAppointment(addressList.value[index].id)
  let hasPatient = data
  if (status !== 200) throw Error('Error occurred on get addresses')
  if (hasPatient) confirm(ModalWarningType.WARNING)
  else handleDelete(index)
  setBtnDialogLoading(false)
}

const handleDelete = async index => {
  if (loadingChangeAdd.value) return
  loadingChangeAdd.value = true
  setBtnDialogLoading(true)
  try {
    await deleteAddress(addressList.value[index].id, {
      newAddress: filteredAddresses.value[migrationAddressIndex.value].id,
    })
    await fillAddresses()

    MessageService.success($t('dashboard.addresses.successDelete'))

    // update dashboard status
    dashboardState.fetchDashboardStatus()

    addressStore.refreshAddresses()
    if (addressStore.displayAddrForm) {
      addressStore.displayAddrForm = false
    }
  } catch (e) {
    console.error(e)
  }
  setBtnDialogLoading(false)
  closeModal()
  loadingChangeAdd.value = false
  addressDialog.value = false
}

const countryName = ref('')

watch(country, (newCountry, oldCountry) => {
  if (newCountry !== oldCountry) {
    console.log('country', country)
    autocompleteAddressList.value = []
  }
})

/** Old function used with geocode earth. We keep it so we can rollback just in case */
const generateAutocompleteParams = (text: string): GeocodeEarthParams => {
  const params = {
    text,
    layers: 'address',
  }

  const countryCode = getAlpha3CountryCode(country.value)
  if (country.value && countryCode) {
    params['boundary.country'] = countryCode
  }

  /* TODO open this when API ready and if we want to have btter performances on the geo search
  if (latitude.value && longitude.value) {
    params['focus.point.lat'] = latitude.value
    params['focus.point.lon'] = longitude.value
  }
  */

  return params
}

const resetFields = () => {
  country.value = ''
  addressLine.value = { label: '', value: null }
  postalCode.value = ''
  city.value = ''
  additionalInstructions.value = null
  additionalInstructionsKey.value++
  no_add_yet.value = false
}

const handleAddOrCancelBtn = mode => {
  addressLine.value = { label: '', value: null }
  form.value.reset()
  if (mode == 'add') {
    addressStore.formMode = Mode.ADD
  } else if (mode == 'cancel') {
    resetFields()
    addressStore.formMode = Mode.HIDDEN
  }
  addressStore.displayAddrForm = !addressStore.displayAddrForm
  selectedAdressIndex.value = null
}

const onInputAddress = () => {
  loadingAddresses.value = true
}

const autocompleteRequest = useDebounceFn(async text => {
  if (!text) {
    loadingAddresses.value = false
    return
  }
  try {
    const placesSuggested =
      await googleMapsStore.getAutocompleteSuggestionsPlaces(
        text,
        country.value ? [getAlpha2CountryCodeWithDOMTOM(country.value)] : [],
      )
    autocompleteAddressList.value = placesSuggested
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
  loadingAddresses.value = false
}, 500)
/** Old geocodeearth code autocomplete
const autocompleteRequest = useDebounceFn(async text => {
  if (!text) {
    loadingAddresses.value = false
    return
  }
  try {
    const res = await getGeocodeEarthFeatures(generateAutocompleteParams(text))
    const { data } = res
    const extractedValues: AutocompleteAddress[] = data.features.map(f => {
      return {
        label: f.properties.label,
        value: {
          country: f.properties.country,
          addressLine: f.properties.name,
          postalCode: f.properties.postalcode,
          city: f.properties.locality,
          latitude: f.geometry?.coordinates[0]?.toString(),
          longitude: f.geometry?.coordinates[1]?.toString(),
        },
      }
    })
    autocompleteAddressList.value = extractedValues // Update the items ref with the response data
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`)
  }
  loadingAddresses.value = false
}, 500)
*/

const switchEditMode = (addressItem: AddressInformationData, i) => {
  console.log('addressItem', addressItem)
  addressStore.displayAddrForm = true
  country.value = addressItem.country
  postalCode.value = addressItem.postalCode
  city.value = addressItem.city
  additionalInstructions.value = addressItem.additionalInstructions
  additionalInstructionsKey.value++
  latitude.value = addressItem.latitude
  longitude.value = addressItem.longitude
  onlyRemote.value = addressItem.remote ?? false
  no_add_yet.value = addressItem.noAddYet ?? false
  addLine.value = addressItem.addressLine

  addressLine.value = {
    label: addressItem.addressLine,
    value: {
      addressLine: addressItem.addressLine,
      country: country.value,
      postalCode: postalCode.value,
      city: city.value,
      additionalInstructions: additionalInstructions.value,
      latitude: latitude.value,
      longitude: longitude.value,
      remote: onlyRemote.value,
      noAddYet: no_add_yet.value,
    },
  }

  addressStore.formMode = Mode.EDIT
  selectedAdressIndex.value = i
}

/**
 * Fill the form on click
 * label : string
 * value : suggestion
 * */
const handleSelectAutocomplete = async (addressItem: {
  label: string
  value: any
}) => {
  addressItem.value = await googleMapsStore.getFormatedAddressFromPlace(
    addressItem.value,
  )

  country.value = addressItem.value.country
  postalCode.value = addressItem.value.postalCode
  city.value = addressItem.value.city

  latitude.value = addressItem.value.latitude
  longitude.value = addressItem.value.longitude
  addressLine.value = {
    label: addressItem.value.addressLine,
    value: {
      addressLine: addressItem.value.addressLine,
      country: country.value,
      postalCode: postalCode.value,
      city: city.value,
      additionalInstructions: additionalInstructions.value,
      latitude: latitude.value,
      longitude: longitude.value,
    },
  }
}

const addAddressLoading = ref(false)
const loadingChangeAdd = ref(false)

const handleUpdateAddresses = async () => {
  setBtnDialogLoading(true)
  form.value.validate()
  if (!form.value.isValid) {
    return
  }

  const updatedList: AddressUpdateParams[] = JSON.parse(
    JSON.stringify(addressList.value),
  ).map(address => {
    const addr = { ...address }
    if (!Object.prototype.hasOwnProperty.call(addr, 'remote')) {
      addr.remote = false
    }
    return addr
  })

  const currentFormItem: AddressUpdateParams = {
    additionalInstructions: additionalInstructions.value
      ? additionalInstructions.value
      : null,
    addressLine: no_add_yet.value ? addLine.value : addressLine?.value?.label,
    city: city.value,
    country: country.value,
    postalCode: postalCode.value,
    latitude: latitude.value,
    longitude: longitude.value,
    remote: onlyRemote.value,
    noAddYet: no_add_yet.value,
  }

  if (formMode.value == Mode.ADD) {
    addAddressLoading.value = true
    updatedList.push(currentFormItem)
  } else if (formMode.value == Mode.EDIT) {
    loadingChangeAdd.value = true
    const { id } = addressList.value[selectedAdressIndex.value]
    currentFormItem.id = id
    updatedList.splice(selectedAdressIndex.value, 1, currentFormItem)
  }

  try {
    const res = await updateAddresses(updatedList)
    if (res.status == 200) {
      addressList.value = formatReceivedAddresses(res.data)
      resetFields()
      form.value.reset()

      addressStore.displayAddrForm = false
      MessageService.success(
        $t('dashboard.addresses.successUpdate', {
          count: addressList.value.length,
        }),
      )
      addressStore.totalAddr = addressList.value.length
      addressStore.refreshAddresses()

      // dialogStore.resetDialogTimeSlotCreationProfileAddress()
    }

    if (formMode.value == FormMode.ADD) {
      // update dashboard status
      dashboardState.fetchDashboardStatus()
    }
  } catch (e) {
    MessageService.error($t('dashboard.addresses.errorUpdate'))
    console.error(e)
  }
  addAddressLoading.value = false
  setBtnDialogLoading(false)
  closeModal()
  loadingChangeAdd.value = false
  addressDialog.value = false
}

const fillGeolocation = () => {
  // console.log('position.coords.latitude.toString()', position.coords.latitude.toString())
  navigator.geolocation.getCurrentPosition(
    position => {
      latitude.value = position.coords.latitude.toString()
      longitude.value = position.coords.longitude.toString()
    },
    error => {
      console.error('an error has occured:', error)
    },
  )
}

const fillAddressesLoading = ref(false)
const fillAddresses = async () => {
  fillAddressesLoading.value = true
  const { status, data } = await getAddresses()
  fillAddressesLoading.value = false
  if (status !== 200) throw Error('Error occurred on get addresses')
  addressList.value = formatReceivedAddresses(data)
  addressStore.totalAddr = addressList.value.length
}

onMounted(async () => {
  LoadingBackdropService.start()
  const resp = await fillGeolocation()
  await fillAddresses()
  LoadingBackdropService.stop()
})

const updatePosition = newPosition => {
  if (!no_add_yet.value) return false
  console.log('new position =>', newPosition)
  ;(latitude.value = newPosition.latitude),
    (longitude.value = newPosition.longitude)
}

const countryCenter = computed(() => {
  return country.value
})

const searchPlace = ref<string>('')
const searchThePlace = ref<string>('')
const streetLoad = ref(false)

watch(
  () => searchPlace.value,
  () => {
    setTimeout(() => {
      searchThePlace.value = searchPlace.value
    }, 1000)
  },
)

watch(
  () => addressStore.displayAddrForm,
  async () => {
    if (addressStore.displayAddrForm && addressStore.formMode === 'add') {
      await fillGeolocation()
    }
  },
)

// watch(searchPlace.value, () => {
//   console.log('search place ===>>>', searchPlace.value)
//   setTimeout(() => {
//     searchThePlace.value = searchPlace.value
//   }, 1000)
// })

const streetLoading = $event => {
  streetLoad.value = $event.value
}

onBeforeUnmount(() => {
  addressStore.formMode = Mode.HIDDEN
  addressStore.displayAddrForm = false
  resetFields()
})
</script>

<style lang="scss" scoped>
.address-text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; // Ensure that the text does not exceed the parent width
  // Media queries to adjust truncation at different screen widths

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    max-width: 25rem;
  }

  @media screen and (max-width: 768px) and (min-width: 577px) {
    max-width: 20rem;
  }

  @media screen and (max-width: 576px) and (min-width: 350px) {
    max-width: 11rem;
  }

  @media screen and (max-width: 349px) and (min-width: 321px) {
    max-width: 9rem;
    font-size: 10px;
  }

  @media screen and (max-width: 576px) and (min-width: 350px) {
    max-width: 11rem;
  }

  @media screen and (max-width: 349px) and (min-width: 321px) {
    max-width: 9rem;
    font-size: 10px;
  }
  @media screen and (max-width: 320px) {
    max-width: 9rem;
    font-size: 11px;
  }
}

.rich-text-field > div {
  text-align: left;
}
.bg-accent.glow {
  box-shadow: 0 0 20px 0px rgb(var(--v-theme-accent));
}
h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 26.6px;
}

.add-block {
  border-radius: 12px;
  padding: 24px;
  background-color: #12c29b1a;
  text-align: left;
}
.add-title {
  font-size: 16px;
  font-weight: 600;
  color: #004838;
}
.add-val {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
}
.add-lists {
  max-width: 1040px;
}
.card-map {
  height: 200px;
  width: 300px;
  max-width: 100%;
}

// Fix on mobile size rounded fields
@media screen and (max-width: 630px) {
  :deep() {
    .v-field__outline__notch {
      max-width: 60%;
    }
  }
  .add-lists {
    width: auto;
  }
}
</style>
