type GenericFormField = {
  [key: string]: any // Accepte n'importe quelle structure de données
  error?: string // Ajout d'une propriété pour stocker les messages d'erreur
}

// Définition du type générique pour un formulaire
type GenericForm = {
  [fieldName: string]: GenericFormField | any
  rootError?: string // Pour stocker les erreurs globales non associées à un champ spécifique
}

// Définition du type pour la réponse du serveur
type ServerResponse = Record<string, string>

export const resetErrors = (form: GenericForm | GenericForm[]): void => {
  if (Array.isArray(form)) {
    form.forEach(f => resetErrors(f))
  } else {
    Object.keys(form).forEach(key => {
      if (
        typeof form[key] === 'object' &&
        form[key] !== null &&
        'error' in form[key]
      ) {
        form[key].error = undefined
      }
    })
  }
}

/**
 * Updates the error messages of form fields based on the server response.
 * Also collects any unmatched errors.
 *
 * @param {GenericForm | GenericForm[]} forms - The form or array of forms to update the errors on.
 * @param {ServerResponse} serverResponse - The server response containing the error messages.
 * @returns {string[]} - An array of unmatched errors.
 */
export const handleServerErrors = (forms, serverResponse) => {
  resetErrors(forms) // Assurez-vous que cette fonction remet à zéro les erreurs existantes

  const unmatchedErrors = []

  // Convert forms to an array if it's not already
  const formsArray = Array.isArray(forms) ? forms : [forms]

  const applyErrors = (form, response, allForms) => {
    for (const [key, message] of Object.entries(response)) {
      let matched = false // Flag pour vérifier si le champ existe dans un des formulaires

      for (const checkForm of allForms) {
        if (key in checkForm) {
          matched = true // Le champ existe dans un des formulaires
          break // Sortir de la boucle dès que le champ correspondant est trouvé
        }
      }

      if (matched) {
        // Appliquer l'erreur seulement si le champ est trouvé dans le formulaire courant
        if (key in form) {
          if (typeof form[key] === 'object' && form[key] !== null) {
            // check if the field is an array of objects :
            if (
              'value' in form[key] &&
              Array.isArray(form[key].value) &&
              form[key].value.every(
                item => typeof item === 'object' && item !== null,
              )
            ) {
              // Gérer un cas spécifique où le champ est un tableau
              if (Array.isArray(message) || typeof message === 'object') {
                form[key].value.forEach((item, index) => {
                  if (index in message) {
                    item.error = message[index] // Appliquer l'erreur à l'élément spécifique du tableau
                  }
                })
              }
            } else {
              // Cas général pour les champs non tableaux
              form[key].error = message
            }
          }
        }
      } else {
        // Ajouter l'erreur aux erreurs non appariées seulement si aucun formulaire n'a le champ
        unmatchedErrors.push(message)
      }
    }
  }

  // Appliquer les erreurs pour chaque formulaire
  formsArray.forEach(form => applyErrors(form, serverResponse, formsArray))

  return [...new Set(unmatchedErrors)] // Filtrer les doublons
}

export const scrollToFirstError = () => {
  const firstErrorElement = document.querySelector(
    '.v-input--error, .text-error',
  )
  if (firstErrorElement) {
    firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
