import { AxiosResponse } from 'axios'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api.d'
import { LoginResponse } from '@/api/auth/auth.d'

const httpClient = createHttpClient(`${import.meta.env.VITE_API_URL}/api/auth`)

export const login = async (): Promise<ApiResponse<LoginResponse>> => {
  const response: AxiosResponse<LoginResponse> =
    await httpClient.post<LoginResponse>('/login')
  return {
    status: response.status,
    data: response.data,
  }
}
