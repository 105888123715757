import { AxiosResponse } from 'axios'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api.d'
import { ProfileInformationData } from './profile.d'

const httpClient = createHttpClient('/profile')

export const getProfiles = async (): Promise<
  ApiResponse<ProfileInformationData[]>
> => {
  const response: AxiosResponse<ProfileInformationData[]> =
    await httpClient.get<ProfileInformationData[]>('/')
  return {
    status: response.status,
    data: response.data,
  }
}
