<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import UpdateCardDialog from '@/components/dialog/UpdateCardDialog.vue'

const { t: $t, d, n } = useI18n()
const subscriptionStore = useSubscriptionStore()
const displayModal = ref(false)
const formatCardNumber = computed(() => {
  if (!subscriptionStore.cardData) return

  if (subscriptionStore.cardData.lastFour) {
    return `**** **** **** ${subscriptionStore.cardData.lastFour}`
  }

  return $t('common.notSpecified')
})

const formatExpired = computed(() => {
  if (!subscriptionStore.cardData) return
  if (subscriptionStore.cardData.expiryMonth) {
    return `${String(subscriptionStore.cardData.expiryMonth).padStart(2, '0')} / ${subscriptionStore.cardData.expiryYear}`
  }
  return $t('common.notSpecified')
})

const billAddress = computed(() => {
  if (!subscriptionStore.userInformation) return
  const { countryCompany, cityCompany } = subscriptionStore.userInformation

  if (countryCompany || cityCompany) {
    return [countryCompany, cityCompany].filter(Boolean).join(' - ')
  }

  return $t('common.notSpecified')
})
</script>

<template>
  <div class="mt-8 d-flex flex-column ga-3">
    <span class="text-dark-green font-weight-medium">{{
      $t('dashboard.subscription.payment_mode')
    }}</span>
    <v-card elevation="0" color="white" border class="pa-5" rounded="xl">
      <div class="d-flex flex-column ga-10 position-relative">
        <div>
          <v-row>
            <v-col cols="12">
              <div class="title text-gray-80 font-weight-bold">
                {{ $t('dashboard.subscription.card_number') }}
              </div>
              <div class="value text-gray-80">{{ formatCardNumber }}</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" sm="4">
              <div class="title text-gray-80 font-weight-bold">
                {{ $t('dashboard.subscription.expired') }}
              </div>
              <div class="value text-gray-80">{{ formatExpired }}</div>
            </v-col>
            <v-col cols="6" sm="4">
              <div class="title text-gray-80 font-weight-bold">CCV</div>
              <div class="value text-gray-80">***</div>
            </v-col>

            <!-- <v-col cols="12" sm="4">
              <div class="title text-gray-80 font-weight-bold">
                {{ $t('dashboard.subscription.bill_address') }}
              </div>
              <div class="value text-gray-80">{{ billAddress }}</div>
            </v-col> -->
          </v-row>
        </div>
        <img
          @click="displayModal = true"
          class="position-absolute cursor-pointer"
          style="right: 0; top: 10px"
          width="20"
          height="20"
          src="@/assets/edit.png"
        />
      </div>
      <div
        class="text-disabled mt-2"
        v-if="!subscriptionStore.cardData?.lastFour"
      >
        <span
          v-if="
            subscriptionStore.subscriptionStatus &&
            subscriptionStore.subscriptionStatus['isLifetime']
          "
          >{{ $t('dashboard.subscription.add_payment_card_islife') }}</span
        >
        <span v-else>{{ $t('dashboard.subscription.add_payment_card') }}</span>
      </div>
      <p
        v-if="subscriptionStore.isCardExpired"
        class="font-italic description mt-2"
      >
        {{ $t('expired-card') }}
      </p>
    </v-card>

    <UpdateCardDialog
      v-if="displayModal"
      :dialog="displayModal"
      @update:close="displayModal = false"
    />
  </div>
</template>

<style scoped lang="scss">
.description {
  font-size: 14px;
  color: rgb(201, 97, 97);
}
.title {
  font-size: 16px;
}

.value {
  font-size: 14px;
}

@media (max-width: 599px) {
  .title {
    font-size: 14px;
  }

  .value {
    font-size: 12px;
  }

  .description {
    font-size: 12px;
  }
}
</style>
