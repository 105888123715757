import { AxiosResponse } from 'axios'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api.d'
import {
  AccountInformationData,
  AccountUpdateParams,
  AccountUpdateResponse,
} from '@/api/account/account.d'
import {
  AddressDeleteParams,
  AddressInformationData,
  AddressUpdateParams,
  AddressUpdateResponse,
  CheckAppointmentResponse,
} from './address'

const httpClient = createHttpClient('/account')

export const getInformations = async (): Promise<
  ApiResponse<AccountInformationData>
> => {
  const response: AxiosResponse<AccountInformationData> =
    await httpClient.get<AccountInformationData>('/informations')
  return {
    status: response.status,
    data: response.data,
  }
}

export const updateInformations = async (
  params: AccountUpdateParams,
): Promise<ApiResponse<AccountUpdateResponse>> => {
  const response: AxiosResponse<AccountUpdateResponse> =
    await httpClient.put<AccountUpdateResponse>('/informations', params)
  return {
    status: response.status,
    data: response.data,
  }
}

export const getAddresses = async (): Promise<
  ApiResponse<AddressInformationData[]>
> => {
  const response: AxiosResponse<AddressInformationData[]> =
    await httpClient.get<AddressInformationData[]>('/address')
  return {
    status: response.status,
    data: response.data,
  }
}

export const updateAddresses = async (
  params: AddressUpdateParams[],
): Promise<ApiResponse<AddressUpdateResponse[]>> => {
  const response: AxiosResponse<AddressUpdateResponse[]> = await httpClient.put<
    AddressUpdateResponse[]
  >('/address', params)
  return {
    status: response.status,
    data: response.data,
  }
}

export const deleteAddress = async (
  id: string,
  params: AddressDeleteParams,
): Promise<ApiResponse<AddressUpdateResponse[]>> => {
  const response: AxiosResponse<AddressUpdateResponse[]> =
    await httpClient.delete<AddressUpdateResponse[]>('/address/' + id, {
      headers: {
        'Content-Type': 'application/json',
      },
      data: params,
    })
  return {
    status: response.status,
    data: response.data,
  }
}

export const checkAppointment = async (
  id: string,
): Promise<ApiResponse<CheckAppointmentResponse[]>> => {
  const response: AxiosResponse<CheckAppointmentResponse[]> =
    await httpClient.get<CheckAppointmentResponse[]>(
      '/address/' + id + '/has-patient',
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  return {
    status: response.status,
    data: response.data,
  }
}

export const updatePassword = async (payload: any) => {
  try {
    const response: AxiosResponse = await httpClient.put('/security', payload)
    return {
      status: response.status,
      data: response.data,
    }
  } catch (_) {}
}
