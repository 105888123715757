export function formatTime(minutes) {
  // Calculer les heures et les minutes
  const heures = Math.floor(minutes / 60)
  const minutesRestantes = minutes % 60

  // Générer la chaîne formatée
  const heureStr = 0 === heures ? '' : `${heures} h`
  const minuteStr = 0 === minutesRestantes ? '' : `${minutesRestantes} min`

  // Retourner la chaîne combinée
  return `${heureStr} ${minuteStr}`
}
