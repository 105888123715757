import { nextTick, ref } from 'vue'

export const useStripe = theme => {
  const stripe = ref(null)
  const stripeElements = ref(null)
  const stripeCard = ref(null)
  const stripeCardExpiry = ref(null)
  const stripeCardCvc = ref(null)
  const stripeError = ref('')

  const initStripe = () => {
    // if (stripe.value !== null) {
    //   return
    // }
    stripe.value = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
    stripeElements.value = stripe.value.elements()

    const style = {
      base: {
        iconColor: theme.current.value.colors.primary,
        color: theme.current.value.colors.primary,
        fontSize: '16px',
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        '::placeholder': {
          color: 'rgba(0, 0, 0, 0.6)',
          fontWeight: 400,
        },
      },
      invalid: {
        iconColor: theme.current.value.colors.error,
        color: theme.current.value.colors.error,
      },
    }

    stripeCard.value = stripeElements.value.create('cardNumber', {
      style: style,
      showIcon: true,
    })
    stripeCardExpiry.value = stripeElements.value.create('cardExpiry', {
      style: style,
    })
    stripeCardCvc.value = stripeElements.value.create('cardCvc', {
      style: style,
    })
    nextTick(() => {
      stripeCard.value.mount('#card-element')
      stripeCardExpiry.value.mount('#card-expiry')
      stripeCardCvc.value.mount('#card-cvc')
      stripeCard.value.on('change', event => {
        if (event.error) {
          stripeError.value = event.error.message
        } else {
          stripeError.value = ''
        }
      })
    })
  }

  return {
    stripe,
    stripeElements,
    stripeCard,
    stripeCardExpiry,
    stripeCardCvc,
    stripeError,
    initStripe,
  }
}
