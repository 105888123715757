import { CalendarAppSingleton } from '@holitime/schedule-x-shared'
import { ref } from 'vue'

// Sidebar plugin to manage the same state as in the @holitime/calendar package
export class CalendarsUpdaterPlugin {
  name: string = 'calendars-updater'
  $app!: CalendarAppSingleton

  destroy(): void {}

  init($app: CalendarAppSingleton): void {
    this.$app = $app
  }
}
export const calendarsUpdaterPlugin = new CalendarsUpdaterPlugin()

/** State and method for header */
export const useHeaderState = () => {
  return {
    updateHeaderState(newText: string) {
      if (calendarsUpdaterPlugin.$app?.config?.plugins?.header) {
        calendarsUpdaterPlugin.$app.config.plugins.header.textSwitchBtn.value =
          newText
      }
    },
  }
}

/** State and method for sidebar */
const sidebarState = ref(null)
const initSidebarState = () => {
  if (calendarsUpdaterPlugin.$app?.config?.plugins?.sidebar) {
    sidebarState.value =
      calendarsUpdaterPlugin.$app.config.plugins.sidebar.isOpen.value
  }
}
export const useSidebarState = () => {
  return {
    sidebarState,
    initSidebarState,
    updateSidebarState(newState: boolean) {
      if (calendarsUpdaterPlugin.$app?.config?.plugins?.sidebar) {
        calendarsUpdaterPlugin.$app.config.plugins.sidebar.isOpen.value =
          newState
        sidebarState.value = newState
      }
    },
  }
}
