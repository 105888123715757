<template>
  <v-container fluid class="pa-0">
    <div class="d-flex justify-space-between ga-5">
      <div class="py-0" style="flex: 1">
        <template v-if="subscriptionStore.loadSubscription">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <SubscriptionInfo />
          <div v-if="smAndDown" class="my-5 d-flex flex-column ga-5">
            <ChangeSubscription
              :currentFormule="true"
              :display="false"
              color="#F5F5F5"
            />
            <v-btn
              v-if="subscriptionStore.subscriptionStatus['type'] !== 'life'"
              @click="subscriptionStore.toggleSubscription"
              block
              color="secondary"
              >{{ $t('change_subscription') }}</v-btn
            >
            <span
              v-if="
                subscriptionStore.subscriptionStatus &&
                subscriptionStore.subscriptionStatus['type'] != 'lifetime' &&
                subscriptionStore.subscriptionStatus['type'] != 'life' &&
                subscriptionStore.subscriptionStatus['status'] != 'canceled'
              "
              class="d-flex justify-center cursor-pointer text-decoration-underline cancel-subscription"
              @click="() => subscriptionStore.toggleCancelSubscription()"
              >{{ $t('cancel_my_subscription') }}</span
            >
          </div>
        </template>

        <template v-if="subscriptionStore.loadInformation">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <DiscountCodeList
            v-if="subscriptionStore.subscriptionStatus['type'] != 'life'"
          />
          <BillingInfo />
        </template>

        <template v-if="subscriptionStore.loadCard">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <PaymentMethode />
        </template>
      </div>

      <template v-if="!smAndDown">
        <div class="">
          <SubscriptionPlan :plans="SubscriptionData[selectedPlan]" />
          <div
            class="w-66 mx-auto d-flex justify-center mt-3"
            @click="() => subscriptionStore.toggleCancelSubscription()"
          >
            <span
              v-if="
                subscriptionStore.subscriptionStatus &&
                subscriptionStore.subscriptionStatus['type'] != 'lifetime' &&
                subscriptionStore.subscriptionStatus['type'] != 'life' &&
                subscriptionStore.subscriptionStatus['status'] != 'canceled'
              "
              class="cursor-pointer text-decoration-underline cancel-subscription"
              >{{ $t('cancel_my_subscription') }}</span
            >
          </div>
        </div>
      </template>
    </div>

    <v-row>
      <v-col cols="12">
        <template v-if="subscriptionStore.paymentListLoading">
          <div class="text-center py-5 font-italic">{{ $t('loading') }}</div>
        </template>
        <template v-else>
          <Payments />
        </template>
      </v-col>
    </v-row>

    <ChangeSubscriptionDialog v-if="subscriptionStore.changeSubscription" />
    <CancelSubscriptionDialog v-if="subscriptionStore.cancelSubscription" />
  </v-container>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed, Ref } from 'vue'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import BillingInfo from '@/components/subscription/BillingInfo.vue'
import PaymentMethode from '@/components/subscription/PaymentMethode.vue'
import SubscriptionInfo from '@/components/subscription/SubscriptionInfo.vue'
import Payments from '@/components/subscription/Payments.vue'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import { Plan } from '@/shared/subscription'
import SubscriptionPlan from '@/components/SubscriptionPlan.vue'
import { SubscriptionData } from '@/shared/subscription'
import ChangeSubscription from '@/components/ChangeSubscription.vue'
import ChangeSubscriptionDialog from '@/components/dialog/ChangeSubscriptionDialog.vue'
import { useDisplay, useTheme } from 'vuetify'
import CancelSubscriptionDialog from '../dialog/CancelSubscriptionDialog.vue'
import DiscountCodeList from '@/components/subscription/DiscountCodeList.vue'

const { mdAndDown, mobile, xs, sm, smAndDown, mdAndUp } = useDisplay()

const subscriptionStore = useSubscriptionStore()
const { t: $t, d, n } = useI18n()

const selectedPlan = computed(() => {
  if (subscriptionStore.planChoise && subscriptionStore.planChoise.id) {
    return subscriptionStore.planChoise.id
  }
})

onMounted(async () => {})
</script>

<style lang="scss">
.cancel-subscription {
  size: 11.78px;
  color: #9e9e9e;
  font-weight: 600;
  line-height: 17.67px;
}
</style>
