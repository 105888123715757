import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export const useValidate = () => {
  const { t } = useI18n()

  const emailRules = computed(() => [
    (v: string) => !!v || t('login.error.emailRequired'),
    (v: string) => /.+@.+\..+/.test(v) || t('login.error.emailInvalid'),
  ])

  const required = computed(() => [
    (v: string) => !!v || t('accountForm.fieldRequired'),
  ])

  const maxLengthRule = computed(() => [
    (v: string) => v.length <= 1000 || t('patient.maxLength'),
  ])

  const messageRule = computed(() => [
    (v: string) => !!v || t('accountForm.fieldRequired'),
    (v: any) => v.length <= 500 || t('500_caracteres'),
  ])

  return { emailRules, required, maxLengthRule, messageRule }
}
