<template>
  <v-dialog
    :model-value="isOpen"
    max-width="550px"
    persistent
    @update:modelValue="closeModal"
    transition="dialog-top-transition"
  >
    <v-card class="timeslot-modal">
      <v-card-title class="modal-header">
        <div></div>
        <span class="modal-title text-primary">Suppression de créneaux</span>
        <v-btn
          variant="text"
          color="primary"
          icon="mdi-close-circle-outline"
          @click="closeModal"
        >
        </v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column">
        <v-icon color="error" class="warning-icon">mdi-alert-circle</v-icon>

        <v-card-subtitle class="modal-subtitle mb-5">
          Nous avons remarqué un chevauchement de créneaux.
        </v-card-subtitle>

        <div class="timeslot-info">
          <div>
            <strong>Profils :</strong> {{ timeslot?.profiles }}<br />
            <strong>Adresse :</strong> {{ timeslot?.address }}<br />
            <strong>Date <span v-if="timeslot.repeat">de début</span> :</strong>
            {{ formattedStartDate }}<br />
            <strong v-if="timeslot.repeat">Date de fin : </strong>
            <span v-if="timeslot.dateEnd && timeslot.repeat">
              {{ formattedEndDate }}<br
            /></span>
            <span v-if="!timeslot.dateEnd && timeslot.repeat">
              Indéfinement <br
            /></span>
            <strong>Heure de début :</strong> {{ formatStartTime }}<br />
            <strong>Heure de fin :</strong> {{ formatEndTime }}<br />
            <strong>Type de créneaux :</strong>
            {{ timeslot.repeat ? 'Répété' : 'Unitaire' }}<br />
            <strong v-if="timeslot.repeat">Jours :</strong>
            <span v-if="timeslot.repeat">{{ formattedDays }}</span>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="modal-actions">
        <v-btn class="w-50" color="grey" variant="flat" @click="closeModal"
          >Annuler</v-btn
        >
        <v-btn
          class="w-50"
          variant="flat"
          color="red-lighten-1"
          @click="deleteTimeslot"
          >Supprimer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

const props = defineProps({
  timeslot: {
    type: Object as () => {
      profiles: string
      address: string
      date: string
      dateEnd: string
      start: string
      end: string
      repeat: boolean
      days: number[]
      timezone: string
    },
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['close', 'delete'])

const closeModal = () => {
  emit('close')
}

const deleteTimeslot = () => {
  emit('delete')
}

// Fonction pour formater la date
const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return new Date(dateString).toLocaleDateString('fr-FR', options)
}

const daysOfWeek = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
]

const formattedDays = computed(() => {
  return props.timeslot.days.map(day => daysOfWeek[day]).join(', ')
})

const formatStartTime = computed(() =>
  dayjs(props.timeslot.start)
    // .tz(props.timeslot.timezone)
    .utc()
    .tz(dayjs.tz.guess())
    .format('HH:mm'),
)
const formatEndTime = computed(() =>
  dayjs(props.timeslot.end)
    // .tz(props.timeslot.timezone)
    .utc()
    .tz(dayjs.tz.guess())
    .format('HH:mm'),
)
const formattedStartDate = computed(() => formatDate(props.timeslot.date))
const formattedEndDate = computed(() =>
  props.timeslot.dateEnd
    ? formatDate(
        dayjs
          .utc(props.timeslot.dateEnd)
          .tz(dayjs.tz.guess())
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
      )
    : formatDate(props.timeslot.date),
)
</script>

<style scoped>
.v-card-text {
  background-color: white;
}
.timeslot-modal {
  border: 1px solid #d3e2e9;
}

.modal-header {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(var(--v-theme-surface-lighten-1)) !important;
}

.warning-icon {
  margin: 10px;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.modal-subtitle {
  color: #ff6f00;
  text-align: center;
  font-weight: 500;
}

.timeslot-info {
  background-color: #e0f7fa;
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
}

.modal-actions {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.modal-actions v-btn {
  margin-left: 10px;
}
</style>
