import countriesAlpha3Dic from '../shared/iso3166-1-alpha-3-countries.json'
import countriesAlpha2Dic from '../shared/iso3166-1-alpha-2-countries.json'
import countriesDOMTOM from '@/shared/5-countries-dom-tom-dic.json'

export function getAlpha3CountryCode(countryName: string): string | undefined {
  for (const code in countriesAlpha3Dic) {
    if (countriesAlpha3Dic[code] === countryName) {
      return code
    }
  }
  return undefined
}

export function getAlpha2CountryCode(countryName: string): string | undefined {
  for (const code in countriesAlpha2Dic) {
    if (countriesAlpha2Dic[code] === countryName) {
      return code
    }
  }
  return undefined
}

export function getAlpha2CountryCodeWithDOMTOM(
  countryName: string,
): string | undefined {
  return countriesDOMTOM[countryName]
}
