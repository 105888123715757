import { FormattedAddressItem } from '@/components/forms/addressForm/addressUtils'

export class GoogleMapsService {
  private apiKey: string
  private version: string
  private scriptLoaded: Promise<void> | null = null
  private librarySet: Set<string> = new Set()
  private apiBaseUrl: string = 'https://maps.googleapis.com/maps/api/js'
  private errorMessage: string =
    'The Google Maps JavaScript API could not load.'

  constructor(apiKey: string, version: string = 'weekly') {
    this.apiKey = apiKey
    this.version = version
  }

  private loadScript(): Promise<void> {
    if (this.scriptLoaded) {
      return this.scriptLoaded
    }

    this.scriptLoaded = new Promise((resolve, reject) => {
      const script = document.createElement('script')
      const params = new URLSearchParams({
        key: this.apiKey,
        v: this.version,
        libraries: [...this.librarySet].join(','),
        callback: 'initMapCallback',
      })

      script.src = `${this.apiBaseUrl}?${params}`
      script.async = true
      script.defer = true

      script.onload = () => resolve()
      script.onerror = () => reject(new Error(this.errorMessage))

      document.head.appendChild(script)
    })

    return this.scriptLoaded
  }

  public importLibrary(library: string): Promise<void> {
    this.librarySet.add(library)
    return this.loadScript()
  }

  public async initPlacesLibrary() {
    await this.importLibrary('places')

    // @ts-ignore
    const { Place, AutocompleteSessionToken, AutocompleteSuggestion } =
      // @ts-ignore
      (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary

    return { Place, AutocompleteSessionToken, AutocompleteSuggestion }
  }
}

export function initGoogleMapsService(apiKey: any) {
  return new GoogleMapsService(apiKey)
}

export function getCityFromSuggestion(suggestion: any): string | undefined {
  if (!suggestion || !suggestion.addressComponents) {
    return undefined
  }

  const cityComponent = suggestion.addressComponents.find(component => {
    return component.types.includes('locality')
  })

  return cityComponent ? cityComponent.longText : ''
}

export function getPostalCodeFromSuggestion(
  suggestion: any,
): string | undefined {
  if (!suggestion || !suggestion.addressComponents) {
    return undefined
  }

  const cityComponent = suggestion.addressComponents.find(component => {
    return component.types.includes('postal_code')
  })

  return cityComponent ? cityComponent.longText : ''
}

export function getCountryFromSuggestion(suggestion: any): string | undefined {
  if (!suggestion || !suggestion.addressComponents) {
    return undefined
  }

  const cityComponent = suggestion.addressComponents.find(component => {
    return component.types.includes('country')
  })

  return cityComponent ? cityComponent.longText : ''
}
