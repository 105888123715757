import EventBus from '@/utils/eventBus'

const LoadingBackdropService = {
  start: () => {
    EventBus.emit('loading', { active: true })
  },
  stop: () => {
    EventBus.emit('loading', { active: false })
  },
}

export default LoadingBackdropService
