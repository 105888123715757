<template>
  <vue-tel-input
    :styleClasses="[
      props.rounded ? 'rounded-lg' : '',
      props.size,
      errors.length > 0 ? 'error' : '',
      'custum-padding',
    ]"
    v-model="phone"
    :defaultCountry="props.defaultCountry"
    :autoDefaultCountry="props.autoDefaultCountry"
    :onlyCountries="props.onlyCountries"
    :preferredCountries="props.preferredCountries"
    :ignoredCountries="props.ignoredCountries"
    :autoFormat="true"
    :disabled="props.disabled"
    :validCharactersOnly="true"
    :inputOptions="{
      id: '',
      autocomplete: 'on',
      'aria-describedby': '',
      name: 'telephone',
      placeholder: props.label,
      showDialCode: props.showDialCode,
      readonly: false,
      required: props.required,
      type: 'tel',
      styleClasses: '',
      maxlength: 25,
    }"
    :dropdownOptions="{
      disabled: false,
      showDialCodeInList: true,
      showDialCodeInSelection: true,
      showFlags: true,
      showSearchBox: true,
    }"
    @validate="validate"
    @on-input="onChange"
    mode="national"
  ></vue-tel-input>
  <span
    class="text-caption text-error ml-4 animate-slide-down"
    v-for="error in errors"
    :key="error"
    >{{ error }}</span
  >
  <span
    v-if="!errors.length"
    class="text-caption text-disabled ml-4 animate-slide-down"
    >{{ props.hint }}</span
  >
</template>

<script lang="ts" setup>
import { ref, withDefaults, watch } from 'vue'
import './vue-tel-input.css'
import './flags.css'
import { VueTelInput } from 'vue-tel-input'
import { PhoneProps } from '@/components/inputs/phone/phone.d'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = withDefaults(defineProps<PhoneProps>(), {
  required: false,
  disabled: false,
  label: 'Enter a phone number',
  rules: () => [],
  rounded: false,
  size: 'medium',
  defaultCountry: 'fr',
  autoDefaultCountry: true,
  onlyCountries: () => [],
  preferredCountries: () => [],
  ignoredCountries: () => [],
  showDialCode: true,
  hint: '',
  errorMessages: () => [],
})

const emit = defineEmits(['update:modelValue', 'valide', 'change'])

let phone = ref(props.modelValue)
let errors = ref<string[]>([])
const isPhoneValid = ref(false)

const validate = validation => {
  isPhoneValid.value = validation.valid
  emit('valide', validation.valid)
}

const onChange = (value, phoneObject) => {
  emit('update:modelValue', phone.value)
  emit('change', phoneObject)
  if (props.rules && props.rules.length > 0) {
    errors.value = props.rules
      .map(rule => rule(phone.value))
      .filter(result => result !== true) as string[]
  }
  if (!!phone.value && errors.value.length === 0 && !isPhoneValid.value) {
    errors.value.push(t('input.invalidPhoneNumber'))
  }
  if (isPhoneValid.value || (!phone.value && !props.required)) {
    errors.value = []
  }
}

// watch errorMessages and add them to errors
watch(
  () => props.errorMessages,
  newVal => {
    if (typeof newVal === 'string') {
      errors.value = [newVal]
    } else {
      newVal = newVal.filter(value => value)
      errors.value = newVal
    }
  },
)

// watch modelValue and update phone
watch(
  () => props.modelValue,
  newVal => {
    phone.value = newVal
  },
)
</script>

<style lang="scss" scoped>
@keyframes slide-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-down {
  animation: slide-down 0.5s ease;
}

.custum-padding {
  padding: 3px 0px;
}

:deep {
  .vti__input {
    color: #757575 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
  }
}
</style>
