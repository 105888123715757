// Utilities
import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    preSignupDialogOpen: false,
    donationDialogOpen: false,
    loading: false,
    innerLoading: false,
    pageNotFound: false,
    tempError: null,
    isLeftMenuOpen: null,
    navRole: 'praticien',
    referralUrl: '',
  }),
  actions: {
    setLoading(loading: boolean) {
      this.loading = loading
    },
    setInnerLoading(loading: boolean) {
      setTimeout(() => {
        this.innerLoading = loading
      }, 10)
    },
    toggleLeftMenu() {
      this.isLeftMenuOpen = !this.isLeftMenuOpen
    },
    toggleNavRole() {
      if (this.navRole === 'praticien') {
        this.navRole = 'patient'
      } else {
        this.navRole = 'praticien'
      }
    },

    setUrl(url: string) {
      this.referralUrl = url
    },
  },
})
