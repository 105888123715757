<template>
  <div class="d-flex flex-column grid-container-parent">
    <div
      align="center"
      class="date-time-g ga-2 grid-container d-flex flex-wrap align-center"
      no-gutters
    >
      <!-- Sélecteur de Date -->
      <div class="d-flex ga-1 align-center">
        <v-icon class="a" icon="mdi-calendar-clock" />
        <v-menu
          v-model="dateMenuOpen"
          transition="scale-transition"
          :close-on-content-click="false"
          class="w-100"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              variant="text"
              class="select-date b text-capitalize d-flex align-center py-1 cursor-pointer"
              v-bind="props"
            >
              <span class="text-black">{{ formattedSelectedDate }}</span>
              <v-icon
                class="transition-smooth"
                :class="{ 'rotated-angle': dateMenuOpen }"
                color="black"
                icon="mdi-chevron-down"
              />
            </v-btn>
          </template>
          <v-date-picker
            show-adjacent-months
            hide-header
            @update:model-value="handleSelectDate"
          />
        </v-menu>
      </div>

      <!-- Sélecteurs de Temps -->
      <div class="d-flex ga-2">
        <!-- Sélecteur de Heure de Début -->
        <div class="d-flex ga-2 align-center">
          <span class="c">{{ $t('dashboard.time-slot.from') }}</span>
          <v-menu
            v-model="fromTimeMenuOpen"
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                variant="text"
                class="select-start d d-flex align-center pr-2 py-1 cursor-pointer"
                v-bind="props"
              >
                <span class="text-black">{{ internalStartTime }}</span>
                <v-icon
                  class="transition-smooth"
                  :class="{ 'rotated-angle': fromTimeMenuOpen }"
                  color="black"
                  icon="mdi-chevron-down"
                />
              </v-btn>
            </template>
            <v-time-picker
              v-model="internalStartTime"
              title=""
              color="green"
              format="24hr"
              @change="fromTimeMenuOpen = false"
              @update:hour="updateStartHour"
              @update:minute="updateStartMinute"
            />
          </v-menu>
        </div>

        <!-- Sélecteur de Heure de Fin -->
        <div class="d-flex ga-2 align-center">
          <span class="e">{{ $t('dashboard.time-slot.to') }}</span>
          <v-menu
            v-model="toTimeMenuOpen"
            transition="scale-transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                variant="text"
                class="select-end f d-flex align-center pr-2 py-1 cursor-pointer"
                :disabled="endReadOnly"
                v-bind="props"
              >
                <span class="text-black">{{ internalEndTime }}</span>
                <v-icon
                  class="transition-smooth"
                  :class="{ 'rotated-angle': toTimeMenuOpen }"
                  color="black"
                  icon="mdi-chevron-down"
                />
              </v-btn>
            </template>
            <v-time-picker
              v-model="internalEndTime"
              title=""
              color="green"
              :min="internalStartTime"
              format="24hr"
              @change="toTimeMenuOpen = false"
              @update:hour="updateEndHour"
              @update:minute="updateEndMinute"
            />
          </v-menu>
        </div>
      </div>
    </div>

    <!-- Message d'Erreur -->
    <div
      v-if="hasErrorDate"
      class="text-center text-caption text-red font-italic"
    >
      {{ $t('dashboard.time-slot.dialogTimeSlot.errorSelectedTimes') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { VTimePicker } from 'vuetify/labs/VTimePicker'

const props = defineProps({
  start: { type: String, required: true, default: '09:00' },
  end: { type: String, required: true, default: '17:00' },
  date: { type: String, required: true },
  endReadOnly: { type: Boolean, default: false },
})

const emit = defineEmits(['update:start', 'update:end', 'update:date'])

const internalStartTime = ref(props.start)
const internalEndTime = ref(props.end)
const selectedDate = ref(props.date)

const dateMenuOpen = ref(false)
const fromTimeMenuOpen = ref(false)
const toTimeMenuOpen = ref(false)

// Formatage de la date sélectionnée avec jour abrégé
const formattedSelectedDate = computed(() =>
  dayjs(selectedDate.value, 'YYYY-MM-DD').locale('fr').format('ddd D MMMM'),
)

// Fonctions pour mettre à jour l'heure et la minute de début
const updateStartHour = (newHour: number) => {
  const [, minute] = internalStartTime.value.split(':')
  internalStartTime.value = `${String(newHour).padStart(2, '0')}:${minute}`
  emit('update:start', internalStartTime.value)
}

const updateStartMinute = (newMinute: number) => {
  const [hour] = internalStartTime.value.split(':')
  internalStartTime.value = `${hour}:${String(newMinute).padStart(2, '0')}`
  emit('update:start', internalStartTime.value)
}

// Fonctions pour mettre à jour l'heure et la minute de fin
const updateEndHour = (newHour: number) => {
  const [, minute] = internalEndTime.value.split(':')
  internalEndTime.value = `${String(newHour).padStart(2, '0')}:${minute}`
  emit('update:end', internalEndTime.value)
}

const updateEndMinute = (newMinute: number) => {
  const [hour] = internalEndTime.value.split(':')
  internalEndTime.value = `${hour}:${String(newMinute).padStart(2, '0')}`
  emit('update:end', internalEndTime.value)
}

const handleSelectDate = (newDate: string) => {
  selectedDate.value = dayjs(newDate).format('YYYY-MM-DD')
  emit('update:date', selectedDate.value)
  dateMenuOpen.value = false
}

// Validation des heures
const hasErrorDate = computed(() => {
  const startDateTime = dayjs(
    `${selectedDate.value} ${internalStartTime.value}`,
    'YYYY-MM-DD HH:mm',
  )
  const endDateTime = dayjs(
    `${selectedDate.value} ${internalEndTime.value}`,
    'YYYY-MM-DD HH:mm',
  )
  return (
    endDateTime.isBefore(startDateTime) || endDateTime.isSame(startDateTime)
  )
})

// Watchers pour synchroniser les props avec les états internes
watch(
  [() => props.start, () => props.end, () => props.date],
  ([newStart, newEnd, newDate]) => {
    if (newStart !== internalStartTime.value) internalStartTime.value = newStart
    if (newEnd !== internalEndTime.value) internalEndTime.value = newEnd
    if (newDate !== selectedDate.value) selectedDate.value = newDate
  },
)

watch(internalStartTime, newVal => {
  if (/^\d{2}:\d{2}$/.test(newVal)) {
    emit('update:start', newVal)
    const startDateTime = dayjs(
      `${selectedDate.value} ${internalStartTime.value}`,
      'YYYY-MM-DD HH:mm',
    )
    const endDateTime = dayjs(
      `${selectedDate.value} ${internalEndTime.value}`,
      'YYYY-MM-DD HH:mm',
    )
    if (
      endDateTime.isBefore(startDateTime) ||
      endDateTime.isSame(startDateTime)
    ) {
      const newEndTime = startDateTime.add(30, 'minute').format('HH:mm')
      internalEndTime.value = newEndTime
      emit('update:end', newEndTime)
    }
  }
})

watch(internalEndTime, newVal => {
  if (/^\d{2}:\d{2}$/.test(newVal)) {
    emit('update:end', newVal)
  }
})

watch(selectedDate, newDate => emit('update:date', newDate))
</script>

<style scoped>
@import url('./style.css');
@media (max-width: 540px) {
  .select-date,
  .select-end,
  .select-start {
    padding: 8px !important;
    font-size: 12px;
    border: 1px solid #aaa;
  }
  .date-time-g.ga-3.grid-container {
    justify-content: center;
  }
  .grid-container-parent {
    width: 100%;
  }
  .grid-container {
    display: grid;
    width: 100%;
    grid-template-areas:
      'a b b'
      'c d d'
      'e f f';
  }
  .a {
    grid-area: a;
  }
  .b {
    grid-area: b;
  }
  .c {
    grid-area: c;
  }
  .d {
    grid-area: d;
  }
  .e {
    grid-area: e;
  }
  .f {
    grid-area: f;
  }
}
</style>
