<script setup lang="ts">
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import CloseIcon from '../icons/CloseIcon.vue'
import CancelSubIcon from '../icons/CancelSubIcon.vue'
import { useValidate } from '@/useValidate'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const $t = useI18n().t

const subscriptionStore = useSubscriptionStore()
const { emailRules, required } = useValidate()
const isFormValid = ref(false)
const loading = ref(false)
const confirm = ref(false)
const raison = ref('')
const onSubmit = async () => {
  if (!isFormValid.value) return

  loading.value = true
  try {
    await subscriptionStore.cancelTrialSubscription(raison.value)
  } catch (error) {
  } finally {
    loading.value = false
  }
}

const confirmCancel = () => {
  confirm.value = true
}

const close = async () => {
  if (loading.value) return
  subscriptionStore.toggleCancelSubscription()
}

const feedbackRules = [
  (v: any) => !!v || $t('commonForm.error.required'),

  (v: any) => v.length <= 300 || $t('300_caracteres'),
]
</script>

<template>
  <v-dialog
    :model-value="subscriptionStore.cancelSubscription"
    persistent
    max-width="550"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="title font-weight-bold">{{ $t('cancel_title') }}</span>
        <CloseIcon
          color="#007058"
          size="24"
          class="cursor-pointer mr-2"
          style="position: absolute; right: 0"
          @click="close"
        />
      </v-card-title>

      <v-card-text class="px-10 text-gray-80">
        <div class="d-flex justify-center my-5">
          <CancelSubIcon />
        </div>

        <v-form v-model="isFormValid" @submit.prevent="onSubmit">
          <!-- prémiere information  -->
          <div v-if="!confirm">
            <div class="text-center d-flex flex-column ga-2 mb-2">
              <p>{{ $t('sorry_cancel') }}...</p>
              <p class="font-weight-bold">
                {{ $t('confirm_cancel_msg') }}
              </p>
            </div>

            <div class="d-flex flex-column ga-2 mt-5">
              <p class="text-center">{{ $t('irrevercible_action') }}</p>
              <div class="d-flex ga-2 align-baseline">
                <span class="dot"></span>
                <span>{{ $t('referencing_disabled') }}</span>
              </div>
              <div class="d-flex ga-2 align-baseline">
                <span class="dot"></span>
                <span>{{ $t('profil_disabled') }}</span>
              </div>

              <div class="d-flex ga-2 align-baseline">
                <span class="dot"></span>
                <span>{{ $t('disabled_debit') }}</span>
              </div>

              <div class="d-flex ga-2 align-baseline">
                <span class="dot"></span>
                <span>{{ $t('disabled_discount') }}</span>
              </div>
            </div>
          </div>

          <!-- seconde information avec le formulaire  -->
          <div v-else>
            <div class="text-center d-flex flex-column ga-2 mb-2">
              <p class="font-weight-bold">
                {{ $t('why_leaving_us') }}
              </p>
              <p>{{ $t('can_improve_us') }}</p>
            </div>

            <div>
              <label>{{ $t('your_feedback') }}</label>
              <v-textarea
                v-model="raison"
                :rules="feedbackRules"
                persistent-hint
                rows="8"
                placeholder="Texte"
                :hint="$t('feedback_max_msg')"
              />
            </div>
          </div>

          <!-- bouton  -->
          <div class="d-sm-flex mt-5 ga-sm-3 ga-1" v-if="!confirm">
            <v-btn
              @click="() => confirmCancel()"
              class="w-sm-50 w-100"
              color="#E0E0E0"
            >
              <span class="text-gray-80">{{ $t('next') }}</span>
            </v-btn>
            <v-btn
              @click="() => subscriptionStore.toggleCancelSubscription()"
              class="w-sm-50 w-100 mt-2 mt-sm-0"
              color="secondary"
              >{{ $t('close') }}</v-btn
            >
          </div>

          <div class="d-sm-flex mt-5 ga-sm-3 ga-1" v-else>
            <v-btn
              :disabled="loading"
              :loading="loading"
              type="submit"
              class="w-sm-50 w-100"
              color="#E0E0E0"
            >
              <span class="text-gray-80">{{
                $t('cancel_my_subscription')
              }}</span>
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="() => subscriptionStore.toggleCancelSubscription()"
              class="w-sm-50 w-100 mt-2 mt-sm-0"
              color="secondary"
              >{{ $t('cancel') }}</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #12c29b;
  border-radius: 40px;
  padding: 4px;
}

@media (max-width: 599px) {
  .title {
    color: #007058;
    font-size: 16px !important;
  }
}
</style>
