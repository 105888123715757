export class TimerService {
  private timerId: NodeJS.Timeout | null = null

  startTimer(duration: number, callback: () => void) {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
    this.timerId = setTimeout(callback, duration)
  }

  resetTimer(duration: number, callback: () => void) {
    this.startTimer(duration, callback)
  }

  stopTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId)
      this.timerId = null
    }
  }
}

// Exportez une instance unique de votre service pour être utilisée ailleurs dans votre application
export const timerService = new TimerService()
