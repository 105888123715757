<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  display: Boolean,
})

const emit = defineEmits(['confirmDelete', 'cancel'])
</script>

<template>
  <v-dialog persistent :model-value="display" width="500">
    <v-card
      max-width="400"
      prepend-icon="mdi-delete"
      :text="t('profileDialog.description')"
      :title="t('profileDialog.title')"
    >
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ms-auto"
          :text="t('confirmDialog.confirmBtn')"
          @click="emit('confirmDelete', false)"
        ></v-btn>

        <v-btn
          class="ms-auto"
          :text="t('confirmDialog.cancelBtn')"
          @click="emit('cancel', false)"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
