<template>
  <v-dialog
    :model-value="dialogStore.isDialogTimeSlotOpen"
    persistent
    class="timeSlotDialogModal"
    width="550"
    transition="dialog-top-transition"
  >
    <v-card color="white" class="">
      <v-card-title
        class="d-flex justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="font-weight-bold title">{{
          dialogStore.isEditing
            ? $t('dashboard.time-slot.dialogTimeSlot.editTimeSlot')
            : $t('dashboard.time-slot.createTimeSlot')
        }}</span>
        <v-btn
          class="close-icon mr-2"
          variant="text"
          icon="mdi-close-circle-outline"
          @click="dialogStore.toggleDialogTimeSlot"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-sm-5 pa-2">
        <!-- <div class="d-flex align-center justify-center">
            <SelectProfileAndAddress @update:profile="clearMotives" />
          </div> -->

        <div>
          <SelectProfilesAndAddresses
            :initialProfiles="dialogStore.selectedProfiles"
            :initialAddresses="dialogStore.selectedAddresses"
            @update:profiles="clearMotives"
          />
        </div>
        <div class="py-2">
          <SelectDateAndTime
            v-model:date="dialogStore.selectedDate"
            v-model:start="dialogStore.selectedStartTime"
            v-model:end="dialogStore.selectedEndTime"
            @update:start="onUpdateStartTime"
          />
        </div>
        <div class="d-flex align-center ga-sm-4 ga-2">
          <!-- <v-icon icon="mdi-stethoscope" /> -->
          <div class="w-100">
            <label class="text-gray-80">{{
              $t('dashboard.time-slot.dialogTimeSlot.labelSelectMotive')
            }}</label>
            <v-select
              v-model="dialogStore.selectedAppointmentReasons"
              :items="
                appointmentReasonStore.appointmentReasons.filter(ap =>
                  dialogStore.selectedProfiles.some(
                    profile => profile.id === ap.profile.id,
                  ),
                )
              "
              class="select-reason mt-2"
              clearable
              :error-messages="timeSlotStore.errors"
              rounded
              multiple
              density="compact"
              item-title="label"
              item-value="id"
              return-object
              :placeholder="
                $t('dashboard.time-slot.dialogTimeSlot.placeholderSelectMotive')
              "
              :no-data-text="
                $t('dashboard.time-slot.dialogTimeSlot.noAppointmentReason')
              "
            >
              <template #item="{ item, props }">
                <v-list-item v-bind="props">
                  <div class="d-flex flex-row justify-between">
                    <span class="flex-shrink-1" :class="{ 'fs-10': xs }">{{
                      item.value.label
                    }}</span>
                    <span class="text-caption">{{
                      $t('dashboard.time-slot.dialogTimeSlot.duration', {
                        duration: String(JSON.stringify(item.raw.duration)),
                      })
                    }}</span>
                  </div>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip rounded v-if="index <= 1">{{ item.title }}</v-chip>

                <span
                  v-if="index == 2"
                  class="text-grey text-caption align-self-center"
                >
                  {{
                    $t('dashboard.time-slot.dialogTimeSlot.plusXOther', {
                      n: dialogStore.selectedAppointmentReasons.length - 2,
                    })
                  }}
                </span>
              </template>
            </v-select>
          </div>

          <v-btn
            class="bg-secondary add-reason-btn"
            icon="mdi-plus"
            @click="toggleCreateMotiveDialog"
          >
          </v-btn>
        </div>

        <!-- <div
            class="mx-auto text-decoration-underline text-center text-blue-darken-1 cursor-pointer"
            :class="{ 'text-custom-in-mobile': xs }"
            @click="toggleCreateMotiveDialog"
          >
            {{ $t('dashboard.time-slot.createMotive') }}
          </div> -->
        <DialogMotive
          v-model="showCreateMotiveDialog"
          :default-selected-profile-id="dialogStore.selectedProfile?.id"
        />

        <ColorPicker v-model="dialogStore.color" />

        <!-- {{dialogStore}} -->
        <div class="d-flex flex-column ga-sm-4 ga-2">
          <p class="mt-3 font-bold text-start custom-text-gray">
            {{ $t('dashboard.time-slot.IAcceptRendezVous') }}
          </p>
          <v-radio-group
            class="ga-2 flex radio-group-custom d-flex text-start justify-start align-start"
            inline
            hide-details
            v-model="dialogStore.acceptRemote"
          >
            <v-radio
              :disabled="dialogStore.selectedAddress?.remote"
              color="secondary"
              :label="$t('dashboard.time-slot.in-person')"
              value="in-person"
            ></v-radio>
            <v-radio
              :checked="dialogStore.selectedAddress?.remote"
              color="secondary"
              :label="$t('dashboard.time-slot.remote')"
              value="remote"
            ></v-radio>
            <v-radio
              :disabled="dialogStore.selectedAddress?.remote"
              color="secondary"
              value="both"
              :label="$t('dashboard.time-slot.theTwo')"
            ></v-radio>
          </v-radio-group>
        </div>
        <v-divider class="my-2"></v-divider>
        <div
          v-if="!dialogStore.isEditing"
          class="d-flex justify-content-between align-center ga-4 px-5"
        >
          <span class="custom-text-gray">{{
            $t('dashboard.time-slot.doYouWantToRepeatTimeSlot')
          }}</span>
          <!-- <v-radio-group
              class="d-flex flex-shrink-1 flex-grow-0"
              v-model="dialogStore.repeatTimeSlot"
              inline
              hide-details
            >
              <v-radio
                color="blue"
                :label="$t('dashboard.time-slot.yes')"
                value="yes"
              ></v-radio>
              <v-radio
                color="blue"
                :label="$t('dashboard.time-slot.no')"
                value="no"
              ></v-radio>
            </v-radio-group> -->
          <v-checkbox
            class="d-flex flex-shrink-1 flex-grow-0"
            v-model="dialogStore.repeatTimeSlot"
            color="secondary"
            :true-value="'yes'"
            :false-value="'no'"
            hide-details
          ></v-checkbox>
        </div>
        <div
          v-if="dialogStore.repeatTimeSlot === 'yes'"
          class="d-flex align-start justify-content-between flex-column ga-3"
        >
          <div class="d-flex align-center ga-4">
            <span class="custom-text-gray">{{
              $t('dashboard.time-slot.every')
            }}</span>
            <VTextField
              v-model="dialogStore.everyXWeek"
              hide-details
              density="compact"
              class="flex-grow-0 input-every-x-week"
              :rules="[numberRule]"
              size="xs"
            />
            <span class="custom-text-gray">{{
              $t('dashboard.time-slot.weekThe')
            }}</span>
          </div>

          <v-btn-toggle
            v-model="dialogStore.selectedDays"
            mandatory
            multiple
            density="comfortable"
            divided
            class="v-btn-toggle-custom"
            shaped
          >
            <v-btn
              v-for="day in Object.values(EnumDays).filter(
                v => !isNaN(Number(v)),
              )"
              :key="day"
              :value="day"
              :text="$t('dashboard.time-slot.short-day-' + day)"
              :size="xs ? 'x-small' : 'small'"
            />
          </v-btn-toggle>

          <div class="d-flex flex-column align-start justify-center ga-1">
            <div class="d-flex align-center">
              <label for="" class="custom-text-gray"
                >Fin de la répétition</label
              >

              <v-checkbox
                v-model="dialogStore.isIndefinite"
                class="ml-3"
                :label="$t('Indéfiniment')"
                hide-details
              />
            </div>

            <div v-if="!dialogStore.isIndefinite" class="w-100">
              <SelectDate
                v-model:date="dialogStore.selectedDateEnd"
                @update:date="onUpdateDateEnd"
              />
              <div class="text-gray">
                (Date de fin comprise dans la répétition)
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-end mt-5 ga-2">
          <v-btn
            v-if="dialogStore.isEditing"
            class="px-6"
            variant="outlined"
            color="red-darken-1"
            :loading="loadingDeletion"
            @click="handleAskDeleteTimeSlot"
          >
            {{ $t('dashboard.time-slot.delete') }}
          </v-btn>

          <v-btn
            class="px-6"
            variant="flat"
            color="secondary"
            :loading="timeSlotStore.loading"
            :disabled="!dialogStore.formValid"
            @click="verify"
          >
            {{ $t('dashboard.time-slot.save') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Confirm dialog -->
  <v-dialog
    v-model="confirmDialog"
    persistent
    :min-width="xs ? 0 : 500"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        {{ titleConfirmDialog }}
      </v-card-title>
      <v-card-text class="mx-auto">
        <div class="mb-4">{{ messageConfirmDialog }}</div>
        {{ applyForMessage }}
        <v-radio-group color="secondary" v-model="applyChangesFor">
          <v-radio
            :label="
              $t('dashboard.time-slot.dialogTimeSlot.onlyThisOccurrences')
            "
            value="single"
          ></v-radio>

          <div v-if="applyChangesFor === 'single'" class="font-italic">
            {{ messageChangeSingle }}
          </div>

          <v-radio
            :label="
              $t('dashboard.time-slot.dialogTimeSlot.allFutureOccurrences')
            "
            :value="
              confirmDialogMode === 'deletion' ? 'removeAfterDate' : 'all'
            "
          ></v-radio>
          <div
            v-if="
              applyChangesFor === 'removeAfterDate' || applyChangesFor === 'all'
            "
            class="font-italic"
          >
            {{ messageChangeAll }}
          </div>
        </v-radio-group>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>

        <v-btn
          class="px-6"
          variant="outlined"
          color="black"
          @click="resetConfirmDialog"
        >
          {{ $t('confirmDialog.cancelBtn') }}
        </v-btn>

        <v-btn
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :loading="timeSlotStore.loading"
          @click="applyChanges"
        >
          {{ confirmBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Modal de suppression de créneaux en conflit-->
  <TimeslotConflictDialog
    v-if="dialogStore.isConflictModalOpen"
    :timeslot="dialogStore.conflictTimeslot"
    :isOpen="dialogStore.isConflictModalOpen"
    @close="dialogStore.closeConflictModal"
    @delete="handleAskDeleteTimeSlot()"
  />
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/date-picker.css'
import { Ref, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api'
import {
  VerifyTimeSlotParams,
  VerifyTimeSlotResponse,
} from '@/api/timeslot/verify'

import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import { useTimeSlotStore } from '@/store/timeslot/timeSlotStore'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { EnumDays } from '@/shared/enumDays'

import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import MessageService from '../feedback/message/messageService'
import SelectProfileAndAddress from './SelectProfileAndAddress.vue'
import SelectProfilesAndAddresses from './SelectProfilesAndAddresses.vue'
import SelectDateAndTime from './SelectDateAndTime.vue'
import SelectDate from './SelectDate.vue'
import DialogMotive from './DialogMotive.vue'
import LoadingBackdropService from '../feedback/loadingBackdrop/loadingBackdropService'
import EventBus from '@/utils/eventBus'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import ColorPicker from '@/components/color/ColorPicker.vue'
import { calendarApp } from '@/components/calendar/calendarApp'
import TimeslotConflictDialog from './TimeslotConflictDialog.vue'

dayjs.extend(utc)
dayjs.extend(timezone)

const { xs } = useDisplay()
const httpClient = createHttpClient()
const { t } = useI18n()
const dialogStore = useDialogTimeSlotStore()
const timeSlotStore = useTimeSlotStore()

const appointmentReasonStore = useAppointmentReasonStore()
const clearMotives = () => {
  dialogStore.selectedAppointmentReasons = []
}
const selectedColor = ref<string>('#4467c5')
const showCreateMotiveDialog = ref(false)
const toggleCreateMotiveDialog = () => {
  showCreateMotiveDialog.value = true
}
const numberRule = v => {
  if (v && !String(v).trim()) return true
  if (!isNaN(parseFloat(v)) && v > 0) return true
  return t('dashboard.time-slot.errorNumberRule')
}

const verify = () => {
  if (confirmDialogMode.value === 'edition') {
    applyChangesFor.value = 'single'
  }
  console.log(dialogStore.selectedDate)
  const verifyPayload: VerifyTimeSlotParams = {
    profile: dialogStore.selectedProfile.id,
    address: dialogStore.selectedAddress.id,
    profiles: dialogStore.selectedProfiles.map(profile => profile.id),
    addresses: dialogStore.selectedAddresses.map(address => address.id),
    days: dialogStore.selectedDays,
    weekRepeat: dialogStore.repeatTimeSlot === 'yes',
    weekRepetition:
      dialogStore.repeatTimeSlot === 'yes'
        ? Number(dialogStore.everyXWeek)
        : null,
    date: dialogStore.selectedDate,
    dateEnd: dialogStore.selectedDateEnd,
    startTime: dialogStore.selectedStartTime,
    endTime: dialogStore.selectedEndTime,
    remote: dialogStore.isRemote,
    presential: dialogStore.isPresential,
    appointmentReasons: dialogStore.selectedAppointmentReasons.map(ar => ({
      appointmentReason: ar.id,
    })),
    timezone: dayjs.tz.guess(),
    color: dialogStore.color,
  }
  timeSlotStore.handleCreateOrUpdateTimeSlot(
    verifyPayload,
    applyChangesFor.value,
  )
}
const conflictTimeslotDialog = ref(false)

EventBus.on('deleteConflictTimeslotDialog', () => {
  handleAskDeleteTimeSlot()
})

/** Confirm dialog for edition of multiple occurrences */
const confirmDialogMode: Ref<'deletion' | 'edition'> = ref('edition')
const confirmDialog = ref(false)
EventBus.on('closeTimeslotConfirmDialog', () => {
  confirmDialog.value = false
})
const applyChangesFor: Ref<'removeAfterDate' | 'single' | 'all'> =
  ref('removeAfterDate')
const applyForMessage = ref(
  t('dashboard.time-slot.dialogTimeSlot.applyChangesFor'),
)
const titleConfirmDialog = ref(
  t('dashboard.time-slot.dialogTimeSlot.confirmEdition'),
)
const messageConfirmDialog = ref('')
const messageChangeAll = ref('')
const messageChangeSingle = ref('')
const confirmBtnText = ref(t('confirmDialog.confirmBtn'))

const resetConfirmDialog = () => {
  messageConfirmDialog.value = ''
  messageChangeAll.value = ''
  messageChangeSingle.value = ''
  applyForMessage.value = t(
    'dashboard.time-slot.dialogTimeSlot.applyChangesFor',
  )
  confirmDialogMode.value = 'edition'
  confirmDialog.value = false
  applyChangesFor.value = 'single'
  titleConfirmDialog.value = t(
    'dashboard.time-slot.dialogTimeSlot.confirmEdition',
  )
  messageChangeAll.value = ''
  messageChangeSingle.value = ''
  confirmBtnText.value = t('confirmDialog.confirmBtn')
}

const updateMsgChangeAll = msg => {
  messageChangeAll.value = msg
}

const updateMsgChangeSingle = msg => {
  messageChangeSingle.value = msg
}

EventBus.on(
  'openModalConfirm',
  ({ mode, message, messageChangeAll, messageChangeSingle, change }) => {
    resetConfirmDialog()
    confirmDialogMode.value = mode
    messageConfirmDialog.value = message
    updateMsgChangeAll(messageChangeAll)
    updateMsgChangeSingle(messageChangeSingle)
    confirmDialog.value = true
    applyChangesFor.value = change
  },
)

const loadingDeletion = ref(false)
const handleAskDeleteTimeSlot = async () => {
  const NO_VERIFY = true
  try {
    LoadingBackdropService.start()
    let response: ApiResponse<VerifyTimeSlotResponse>
    let responseSingle: ApiResponse<VerifyTimeSlotResponse>
    if (!NO_VERIFY) {
      response = await httpClient.delete(
        `/timeslot/${dialogStore.timeSlotId}/verify?date=${dialogStore.selectedDate}&change=removeAfterDate`,
      )
      responseSingle = await httpClient.delete(
        `/timeslot/${dialogStore.timeSlotId}/verify?date=${dialogStore.selectedDate}&change=single`,
      )
    }
    if (!NO_VERIFY && response.status != 200)
      throw Error(JSON.stringify(response))

    if (dialogStore.repeatTimeSlot == 'yes') {
      if (!NO_VERIFY && response) {
        messageConfirmDialog.value = response.data.message
        messageChangeAll.value = response.data.messageChangeAll
        messageChangeSingle.value = responseSingle.data.messageChangeSingle
      } else {
        messageConfirmDialog.value = t(
          'dashboard.time-slot.dialogTimeSlot.validateDeleteSlot',
        )
        applyChangesFor.value = 'single'
      }
      confirmDialog.value = true
      confirmDialogMode.value = 'deletion'
      titleConfirmDialog.value = t(
        'dashboard.time-slot.dialogTimeSlot.confirmDeletion',
      )
      applyForMessage.value = t(
        'dashboard.time-slot.dialogTimeSlot.applyDeletionFor',
      )
    } else {
      ConfirmDialogService.confirm({
        title: t('dashboard.time-slot.dialogTimeSlot.confirmDeletion'),
        message: '',
        optionsConfirm: {
          onConfirm: () => {
            handleConfirmDeletion()
          },
          onCancel: () => {},
        },
      })
    }
  } catch (err) {
    if (err.response.status == 422) {
      MessageService.error(err.response.data.message)
    } else {
      MessageService.error(t('dashboard.time-slot.dialogTimeSlot.errorDelete'))
    }
  }
  LoadingBackdropService.stop()
}

const applyChanges = () => {
  if (confirmDialogMode.value === 'deletion') {
    handleConfirmDeletion()
  }
  if (confirmDialogMode.value === 'edition') {
    const verifyPayload: VerifyTimeSlotParams = {
      profile: dialogStore.selectedProfile.id,
      address: dialogStore.selectedAddress.id,
      profiles: dialogStore.selectedProfiles.map(profile => profile.id),
      addresses: dialogStore.selectedAddresses.map(address => address.id),
      days: dialogStore.selectedDays,
      weekRepeat: dialogStore.repeatTimeSlot === 'yes',
      weekRepetition:
        dialogStore.repeatTimeSlot === 'yes'
          ? Number(dialogStore.everyXWeek)
          : null,
      date: dialogStore.selectedDate,
      dateEnd: dialogStore.selectedDateEnd,
      startTime: dialogStore.selectedStartTime,
      endTime: dialogStore.selectedEndTime,
      remote: dialogStore.isRemote,
      presential: dialogStore.isPresential,
      appointmentReasons: dialogStore.selectedAppointmentReasons.map(ar => ({
        appointmentReason: ar.id,
      })),
      timezone: dayjs.tz.guess(),
      color: dialogStore.color,
    }
    timeSlotStore.handleConfirmTimeSlot(verifyPayload, applyChangesFor.value)
  }
}

// Date format YYYY-MM-DD of the  UTC timezone
const handleConfirmDeletion = async () => {
  try {
    LoadingBackdropService.start()

    let date = dayjs(
      `${dialogStore.selectedDate} ${dialogStore.selectedStartTime}:${dialogStore.selectedEndTime}`,
      'YYYY-MM-DD HH:mm',
    )
      .utc()
      .format('YYYY-MM-DD')
    let deleteUrl = `/timeslot/${dialogStore.timeSlotId}/validate?applyChangesFor=${applyChangesFor.value}`
    deleteUrl += `&date=${date}`

    let response: ApiResponse<VerifyTimeSlotResponse> =
      await httpClient.delete(deleteUrl)

    if (response.status != 200) throw Error(JSON.stringify(response))
    MessageService.success(
      t('dashboard.time-slot.dialogTimeSlot.successDelete'),
    )
    if (dialogStore.isConflictModalOpen != true) {
      await timeSlotStore.initTimeSlots()
      dialogStore.toggleDialogTimeSlot()
      confirmDialog.value = false
    } else {
      if (dialogStore.isCurrentTimeslotRepeat == 'yes') {
        dialogStore.repeatTimeSlot = 'yes'
      } else {
        dialogStore.repeatTimeSlot = 'no'
      }
      dialogStore.closeConflictModal()
      confirmDialog.value = false
    }

    if (dialogStore.conflictTimeslot.repeat == true)
      console.log(calendarApp.value.events.getAll())
  } catch (err) {
    if (err.response.status == 422) {
      MessageService.error(err.response.data.message)
    } else {
      MessageService.error(t('dashboard.time-slot.dialogTimeSlot.errorDelete'))
    }
  }
  LoadingBackdropService.stop()
  confirmDialog.value = false
  resetConfirmDialog()
}

function ajouterUneHeure(heureStr: string): string {
  // Séparer l'heure et les minutes
  const [heures, minutes] = heureStr.split(':').map(Number)

  // Créer un objet Date avec l'heure actuelle
  const date = new Date()
  date.setHours(heures)
  date.setMinutes(minutes)

  // Ajouter une heure
  date.setHours(date.getHours() + 1)

  // Récupérer les nouvelles heures et minutes
  const nouvellesHeures = String(date.getHours()).padStart(2, '0')
  const nouvellesMinutes = String(date.getMinutes()).padStart(2, '0')

  // Retourner l'heure au format HH:mm
  return `${nouvellesHeures}:${nouvellesMinutes}`
}

function onUpdateStartTime() {
  dialogStore.selectedEndTime = ajouterUneHeure(dialogStore.selectedStartTime)
}

const onUpdateDateEnd = (date: string) => {
  dialogStore.selectedDateEnd = date
}

// const validateIsRemote = newReason => {
//   if (dialogStore.selectedAddress.remote) {
//     const lastReason = newReason[newReason.length - 1]
//     if (lastReason.remote === 'no') {
//       MessageService.error(
//         t('dashboard.time-slot.dialogTimeSlot.reasonRemoteValidation'),
//       )
//     }
//   }
// }
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
}
.justify-content-between {
  justify-content: space-between;
}

:deep(.v-text-field .v-field) {
  border-radius: 10px !important;
}

:deep(.v-select .v-field.v-field) {
  border-radius: 10px !important;
}

.add-reason-btn {
  width: 40px;
  height: 40px;
}

.input-every-x-week {
  width: 66px;
  &:deep() input.v-field__input {
    text-align: center;
  }
}
.v-btn-toggle-custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: visible;
  /* border: 1px solid grey; */
  :deep() {
    background-color: #fff;
  }
}
.select-motive {
  max-width: 80%;
}
.timeSlotDialogModal {
  max-width: 600px;
}

/* :deep(.timeslot-dialog-card) {
  border-radius: 30px !important;
  overflow-y: scroll;
} */

:deep(.v-card::-webkit-scrollbar) {
  width: 0;
  height: 0;
  display: none;
}

.text-ellipsis {
  text-overflow: ellipsis;
}
</style>
<style>
.radio-group-custom .v-radio {
  --v-selection-control-size: unset !important;
  padding-inline-end: 10px;
}

.radio-group-custom .v-selection-control .v-label {
  margin-left: 10px;
}

.custom-text-gray {
  color: #616161 !important;
}

.v-btn-group .v-btn {
  width: 45px;
  height: 45px !important;
  background-color: rgb(226, 226, 226) !important;
  border-radius: 50%;
  box-shadow: none !important;
  border: none !important;
}

.v-btn-group .v-btn.v-btn--active {
  background: rgba(18, 194, 156, 0.2) !important;
  color: #004838;
}

.title {
  color: #007058;
  font-size: 23px;
}

@media (max-width: 768px) {
  .radio-group-custom .v-selection-control-group label {
    font-size: 12px;
  }
  .v-field__clearable {
    opacity: 1 !important;
  }
  .timeSlotDialogModal {
    width: 100%;
    max-width: 500px;
  }
  .v-btn-group--density-comfortable.v-btn-group {
    height: 35px !important;
  }
  .v-btn-toggle-custom {
    overflow: hidden;
  }
  .select-reason {
    & .v-field__input {
      padding: 8px !important;
      font-size: 12px !important;
      & input {
        top: 12px;
        left: 12px;
      }
    }
  }
  .v-dialog > .v-overlay__content {
    max-width: calc(100% - 18px);
    margin: 9px;
  }
  .text-custom-in-mobile {
    font-size: 12px;
  }

  .title {
    color: #007058;
    font-size: 16px !important;
  }
}
</style>
