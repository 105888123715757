<template>
  <div class="">
    <div>
      <!-- <v-icon icon="mdi-account-multiple-outline" /> -->
      <label for="" class="custom-text-gray">Sélection des profils</label>
      <v-combobox
        density="compact"
        class="mt-1"
        v-model="selectedProfiles"
        :items="profileStore.profiles"
        multiple
        item-title="mainDiscipline.name"
        item-value="id"
        placeholder="Profils"
        chips
        chips-rounded
        clearable
        @update:modelValue="handleSelectProfiles"
      ></v-combobox>
    </div>

    <div>
      <!-- <v-icon icon="mdi-map-marker-outline" /> -->
      <label for="" class="custom-text-gray">Sélection de l'adresse</label>
      <v-combobox
        density="compact"
        class="mt-1"
        v-model="selectedAddresses"
        :items="addressStore.addresses"
        multiple
        item-title="formattedAddress"
        item-value="id"
        placeholder="Selectionner une adresse"
        hide-selected
        variant="outlined"
        clearable
        @update:modelValue="handleSelectAddresses"
        ref="addressCombobox"
      ></v-combobox>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, nextTick, onMounted } from 'vue'
import { useUserStore } from '@/store/user/userStore'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { formatAddress } from '@/utils/formatAddress'
import { useAddressStore } from '@/store/address/addressStore'
import { useProfileStore } from '@/store/profile/profileStore'

// Props for initial values
const props = defineProps({
  initialProfiles: {
    type: Array,
    default: () => [],
  },
  initialAddresses: {
    type: Array,
    default: () => [],
  },
})

const userStore = useUserStore()
const addressStore = useAddressStore()
const profileStore = useProfileStore()
const dialogStore = useDialogTimeSlotStore()
const emit = defineEmits(['update:profiles', 'update:addresses'])

const selectedProfiles = ref([])
const selectedAddresses = ref([])
const addressCombobox = ref(null)

// Profile selection logic
const handleSelectProfiles = profiles => {
  dialogStore.selectedProfiles = profiles
  dialogStore.saveToLocalStorage(
    profiles[0],
    dialogStore.selectedAddresses[0],
    dialogStore.selectedProfiles,
    dialogStore.selectedAddresses,
  )
  emit('update:profiles', profiles)
}

// Address selection logic
const handleSelectAddresses = addresses => {
  if (addresses.length > 1) {
    selectedAddresses.value = [addresses[addresses.length - 1]]
  } else {
    selectedAddresses.value = addresses
  }
  dialogStore.selectedAddresses = selectedAddresses.value
  dialogStore.saveToLocalStorage(
    dialogStore.selectedProfiles[0],
    selectedAddresses.value[0],
    dialogStore.selectedProfiles,
    selectedAddresses.value,
  )
  emit('update:addresses', selectedAddresses.value)

  // Fermer la liste après sélection
  nextTick(() => {
    if (addressCombobox.value) {
      addressCombobox.value.blur()
    }
  })
}

// Computed to format address
const formattedAddress = address => {
  return formatAddress(address, true)
}

// Initialize the component with the provided values on mount
onMounted(() => {
  selectedProfiles.value = props.initialProfiles
  selectedAddresses.value = dialogStore.selectedAddresses
})
</script>

<style scoped>
/* .v-combobox {
  width: 100%;
  margin-bottom: 16px;
  min-width: 400px;
  max-width: 100%;
  height: 40px;
} */

.custom-text-gray {
  color: #616161 !important;
}

/* :deep(.v-combobox .v-field__input) {
  min-height: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
} */

:deep(.v-text-field .v-field) {
  border-radius: 10px !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

:deep(.d-flex .v-combobox .v-chip.v-chip--density-default) {
  border-radius: 22px !important;
}
</style>
