<template>
  <v-dialog :model-value="dialog" max-width="500">
    <v-card>
      <v-card-title>{{
        $t('profileForm.suggestDisciplineForm.title')
      }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-if="!finished"
          ref="discipline"
          :label="$t('profileForm.suggestDisciplineForm.discipline.label')"
          v-model="form.discipline.value"
          :rules="form.discipline.rules"
          :error-messages="form.discipline.error"
        ></v-text-field>
        <template v-else>
          <p class="text-secondary">
            {{ $t('profileForm.suggestDisciplineForm.successMessage') }}
          </p>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="black"
          variant="outlined"
          :text="
            finished
              ? $t('commonDialog.closeBtn')
              : $t('confirmDialog.cancelBtn')
          "
          @click="handleClose"
        >
        </v-btn>
        <v-btn
          v-if="!finished"
          color="secondary"
          variant="outlined"
          :text="$t('profileForm.suggestDisciplineForm.submitBtn')"
          :loading="submitLoading"
          @click="handleSubmit"
        >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { defineProps, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'

const { t } = useI18n()
const discipline = ref(null)
const submitLoading = ref(false)
const finished = ref(false)

defineProps({
  dialog: Boolean,
})
const emit = defineEmits(['update:dialog'])

const form = ref({
  discipline: {
    value: '',
    error: '',
    rules: [
      (v: string) =>
        !!v || t('profileForm.suggestDisciplineForm.discipline.error.required'),
    ],
  },
})

const handleSubmit = () => {
  discipline.value?.validate()
  if (!form.value.discipline.value) {
    return
  }
  const client = createHttpClient('/discipline')
  submitLoading.value = true
  client
    .post('/suggest', {
      name: form.value.discipline.value,
    })
    .then(() => {
      finished.value = true
    })
    .catch(response => {
      if (response.data?.message) {
        form.value.discipline.error = response.data.message
      }
    })
    .finally(() => {
      submitLoading.value = false
    })
}

const handleClose = () => {
  form.value.discipline.value = ''
  form.value.discipline.error = ''
  finished.value = false
  emit('update:dialog', false)
}
</script>

<style scoped lang="scss"></style>
