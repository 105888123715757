import HandPalm from '@/assets/auth/HandPalm.svg'
import app from '@/assets/auth/GoogleChromeLogo.svg'
import standard from '@/assets/auth/standard.svg'
import cabinet from '@/assets/auth/cabinet.svg'
import Handshake from '@/assets/auth/Handshake.svg'

// @ts-ignore
// import { useUserStore } from '@/store/user/userStore'

// const userStore = useUserStore()

export interface Feature {
  title: string
  sub_features: SubFeature[]
}

export interface SubFeature {
  title: string
  availlable?: boolean
  isSoon?: boolean
}

export interface SubTitle {
  title: string
  icon?: string
}

export interface Plan {
  id: string
  title: string
  description: string
  icon?: string
  price: number
  old_price?: number
  discount?: number
  subTitle1?: SubTitle
  subTitle2?: SubTitle
  features: Feature[]
  message?: string
  tva_percent?: number
  finalPrice?: number
  bgIconColor?: string
  bgDesc?: string
}

export interface Subscription {
  monthly: Plan[]
  annual: Plan[]
  lifetime: Plan[]
}

export const FeatureDate: Feature[] = [
  {
    title: 'Profils',
    sub_features: [
      {
        title: 'Vos profils (jusqu’à X3 profils)',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Gestion de votre Patientèle',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Gestion de votre agenda',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Optimisation de votre référencement',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Accompagnement juridique',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Intégration à la communauté Holitime',
        availlable: true,
        isSoon: false,
      },
    ],
  },
  {
    title: 'Coordonnées',
    sub_features: [
      {
        title: 'Adresses de vos prestation (x3)',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Numéro de téléphones',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Adresse email',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Site internet personnel',
        availlable: true,
        isSoon: false,
      },
    ],
  },
  {
    title: 'Agenda',
    sub_features: [
      {
        title: 'Configurer vos créneaux de disponibilité',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Gérer vos rendez-vous',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Rappel, notification par mail',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Rappel, notification par SMS',
        availlable: false,
        isSoon: true,
      },
      {
        title: 'Synchronisation de vos agendas',
        availlable: true,
        isSoon: false,
      },
    ],
  },
  {
    title: 'Référencement',
    sub_features: [
      {
        title:
          'Apparaître dans les résultats de recherche du site par discipline',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Optimisation du référencement dans les moteurs de recherche',
        availlable: true,
        isSoon: false,
      },
    ],
  },
  {
    title: 'Accompagnement juridique',
    sub_features: [
      {
        title: 'Accès aux ressources juridiques',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Réponses aux question juridiques',
        availlable: true,
        isSoon: false,
      },
    ],
  },
  {
    title: 'Réseaux',
    sub_features: [
      {
        title: 'Evaluation par les patients',
        availlable: true,
        isSoon: false,
      },
      {
        title: 'Recommandation entre Praticiens',
        availlable: false,
        isSoon: true,
      },
    ],
  },
]

export const SubscriptionData: Subscription = {
  monthly: [
    {
      id: 'monthly',
      title: 'Référencement Holitime',
      icon: standard,
      description:
        '<div class="text-center">Offre de lancement valable pour les <span class="text-accent">6 premiers mois</span></div>',
      price: 37.5,
      old_price: 50,
      discount: 25,
      subTitle1: {
        title: 'Application web',
        icon: app,
      },
      subTitle2: {
        title: 'Sans engagement',
        icon: HandPalm,
      },
      features: FeatureDate,
      bgIconColor: 'bg-secondary',
      bgDesc: '#004838',
      message: 'facturé mensuellement.',
      finalPrice: 45,
    },
    {
      id: 'cabinet',
      title: 'Référencement Cabinet',
      description: 'Offre bientôt disponible',
      icon: cabinet,
      price: 37.5,
      discount: 0,
      subTitle1: {
        title: 'À partir de 03 praticiens',
      },
      subTitle2: {
        title: 'Application web',
        icon: app,
      },
      features: [],
      bgIconColor: 'bg-blue',
      bgDesc: '#002A61',
    },
  ],
  annual: [
    {
      id: 'annual',
      title: 'Référencement Holitime',
      icon: standard,
      description:
        '<div>Offre de lancement valable pour les <span class="text-accent">6 premiers mois</span></div>',
      price: 300,
      old_price: 600,
      discount: 50,
      subTitle1: {
        title: 'Application web',
        icon: app,
      },
      subTitle2: {
        title: 'Engagement d’un an',
        icon: Handshake,
      },
      features: FeatureDate,
      bgIconColor: 'bg-secondary',
      bgDesc: '#004838',
      message: 'facturé annuellement.',
      finalPrice: 300,
    },
    {
      id: 'cabinet',
      title: 'Référencement Cabinet',
      icon: cabinet,
      description: 'Offre bientôt disponible',
      subTitle1: {
        title: 'À partir de 03 praticiens',
      },
      subTitle2: {
        title: 'Application web',
        icon: app,
      },
      price: 37.5,
      discount: 25,
      features: FeatureDate,
      bgIconColor: 'bg-blue',
      bgDesc: '#002A61',
    },
  ],
  lifetime: [
    {
      id: 'lifetime',
      title: 'Référencement Holitime',
      icon: standard,
      description:
        '<div><span class="text-accent">Offre à durée limitée</span><br>Plus que quelques places disponibles</div>',
      price: 1000,
      old_price: 1000,
      subTitle1: {
        title: 'Application web',
        icon: app,
      },
      discount: 0,
      features: FeatureDate,
      bgIconColor: 'bg-secondary',
      bgDesc: '#004838',
      message: 'facturé en une seule fois.',
      finalPrice: 1000,
    },
    {
      id: 'cabinet',
      title: 'Référencement Cabinet',
      description: 'Offre bientôt disponible',
      icon: cabinet,
      price: 1000,
      old_price: 1000,
      discount: 0,
      subTitle1: {
        title: 'À partir de 03 praticiens',
      },
      subTitle2: {
        title: 'Application web',
        icon: app,
      },
      features: FeatureDate,
      bgIconColor: 'bg-blue',
      bgDesc: '#002A61',
    },
  ],
}
