import axios from 'axios'

export const typesenseClient = axios.create({
  baseURL: import.meta.env.VITE_SEARCH_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'X-TYPESENSE-API-KEY': import.meta.env.VITE_X_TYPESENSE_API_KEY,
  },
})
