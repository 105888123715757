import { ref } from 'vue'
import { defineStore } from 'pinia'
import EventBus from '@/utils/eventBus'
import { TimerService } from '@/services/timerService'
import ConfirmDialogService from '@/components/feedback/confirmDialog/confirmDialogService'
import i18n from '@/plugins/i18n'
import router from '@/router'
import createHttpClient from '@/api/httpClient'
import { useAppointmentManagementStore } from '@/store/practitioner-public-profile/appointmentManagementStore'

const { t } = i18n.global

interface IPublicProfileRouteParams {
  discipline: string
  city: string
  practitionerSlug: string
}

export const useAbsenceServiceStore = defineStore('absence-service', () => {
  const publicProfileParams = ref<IPublicProfileRouteParams>(null)

  const timerService = ref(new TimerService())

  const openTmpAppointmentCanceled = () => {
    EventBus.emit('tmp-appointment-canceled')
  }

  const openWarning = () => {
    timerService.value.startTimer(13 * 60 * 1000, openTmpAppointmentCanceled)

    ConfirmDialogService.confirm({
      title: t('dashboard.appointment.titleAbsenceDialog'),
      message: t('dashboard.appointment.messageAbsenceDialog'),
      confirmBtnLabel: t('dashboard.appointment.confirmBtnAbsenceDialog'),
      cancelBtnLabel: t('dashboard.appointment.cancelBtnAbsenceDialog'),
      optionsConfirm: {
        async onCancel() {
          const appointmentManagementStore = useAppointmentManagementStore()
          const httpClient = createHttpClient()
          await httpClient.delete(
            '/appointment-patient/' +
              appointmentManagementStore.temporaryAppointment.id,
          )
          // stop the timer
          timerService.value.stopTimer()

          await router.push({
            name: 'PractitionerPublicProfile',
            params: {
              ...publicProfileParams.value,
            },
          })
        },
        onConfirm() {
          // reset timer
          resetTimer()
        },
      },
      mandatory: true,
    })
  }

  const startTimer = () => {
    timerService.value.startTimer(13 * 60 * 1000, openWarning)
  }

  const stopTimer = () => {
    timerService.value.stopTimer()
  }

  const resetTimer = () => {
    timerService.value.stopTimer()
    timerService.value.startTimer(13 * 60 * 1000, openWarning)
  }

  return {
    publicProfileParams,
    startTimer,
    stopTimer,
  }
})
