import EventBus from '@/utils/eventBus'

const MessageService = {
  info: (text: string) => EventBus.emit('message', { text, severity: 'info' }),
  success: (text: string) =>
    EventBus.emit('message', { text, severity: 'success' }),
  warning: (text: string) =>
    EventBus.emit('message', { text, severity: 'warning' }),
  error: (text: string) =>
    EventBus.emit('message', { text, severity: 'error' }),
}

export default MessageService
