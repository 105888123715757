<template>
  <v-dialog
    v-model="isOpen"
    persistent
    :min-width="smAndDown ? 100 : 500"
    width="fit-content"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex pa-4 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span>{{
          isEditing
            ? $t('dashboard.time-slot.dialogVacation.titleEdit')
            : $t('dashboard.time-slot.dialogVacation.title')
        }}</span>
        <v-btn
          class="close-icon mr-2"
          variant="text"
          icon="mdi-close"
          @click="handleClose"
        ></v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column ga-5 px-4 px-sm-10 pt-10">
        <div
          class="datetime-selector"
          :class="{
            'd-flex align-center flex-wrap ga-2': !smAndDown,
            'd-flex flex-column ga-2': xs,
          }"
        >
          <div>
            <VIcon icon="mdi-calendar-badge" class="a mr-2" />
            <span class="b">{{
              $t('dashboard.time-slot.dialogVacation.from')
            }}</span>
          </div>
          <SelectDayMonthYear
            class="c"
            v-model:day="startDay"
            v-model:month="startMonth"
            v-model:year="startYear"
          />
          <SelectHourMinute
            class="d"
            v-model:hour="startHour"
            v-model:minute="startMinute"
          />
        </div>
        <div
          class="datetime-selector"
          :class="{
            'd-flex align-center flex-wrap ga-2': !smAndDown,
            'd-flex flex-column ga-2': xs,
          }"
        >
          <div>
            <VIcon icon="mdi-calendar-badge" class="a mr-2" />
            <span class="b">{{
              $t('dashboard.time-slot.dialogVacation.to')
            }}</span>
          </div>
          <SelectDayMonthYear
            class="c"
            v-model:day="endDay"
            v-model:month="endMonth"
            v-model:year="endYear"
          />
          <SelectHourMinute
            class="d"
            v-model:hour="endHour"
            v-model:minute="endMinute"
          />
        </div>
      </v-card-text>
      <v-card-actions
        class="pr-7 pl-5 ga-4 d-flex justify-end align-center pb-8"
      >
        <VAlert
          v-if="showAlert"
          :text="String(alertText)"
          color="red"
          variant="outlined"
          class="flex-grow-1 red-icon"
          closable
        />
        <v-btn
          v-if="isEditing"
          class="px-6"
          variant="outlined"
          color="red-darken-1"
          :loading="loadingDelete"
          @click="openDeleteConfirm"
        >
          {{ $t('dashboard.time-slot.delete') }}
        </v-btn>
        <v-btn
          :disabled="!validDates || !!errorDate"
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :loading="loadingConfirmBtn"
          @click="toggleVerifyDialog"
        >
          {{ $t('dashboard.time-slot.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import createHttpClient from '@/api/httpClient'
import { ref, Ref, computed, watch } from 'vue'
import SelectDayMonthYear from './SelectDayMonthYear.vue'
import SelectHourMinute from './SelectHourMinute.vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { ApiResponse } from '@/api/api'
import MessageService from '../feedback/message/messageService'
import EventBus from '@/utils/eventBus'
import { VacationEditionData } from '@/store/vacation/vacation'
import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import { useTimeSlotStore } from '@/store/timeslot/timeSlotStore'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify/lib/framework.mjs'

const { t } = useI18n()
const { smAndDown, xs } = useDisplay()
const isEditing = ref(false)
const isOpen = ref(false)
EventBus.addListener('toggleVacationDialog', () => {
  isOpen.value = !isOpen.value
})
const vacationId = ref('')
EventBus.addListener('updateVacation', (data: VacationEditionData) => {
  isEditing.value = true
  isOpen.value = true
  vacationId.value = data.id
  startDay.value = data.startDay
  startMonth.value = data.startMonth
  startYear.value = data.startYear
  startHour.value = data.startHour
  startMinute.value = data.startMinute
  endMinute.value = data.endMinute
  endHour.value = data.endHour
  endDay.value = data.endDay
  endMonth.value = data.endMonth
  endYear.value = data.endYear
})

const reset = () => {
  isOpen.value = false
  isEditing.value = false
  startYear.value = t('dashboard.time-slot.dialogVacation.year')
  startMonth.value = t('dashboard.time-slot.dialogVacation.month')
  startDay.value = t('dashboard.time-slot.dialogVacation.day')
  startHour.value = 0
  startMinute.value = 0
  endYear.value = t('dashboard.time-slot.dialogVacation.year')
  endMonth.value = t('dashboard.time-slot.dialogVacation.month')
  endDay.value = t('dashboard.time-slot.dialogVacation.day')
  endHour.value = 23
  endMinute.value = 59
  verifyVacationText.value = ''
}

const handleClose = () => {
  reset()
}

const startYear: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.year'),
)
const startMonth: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.month'),
)
const startDay: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.day'),
)
const endYear: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.year'),
)
const endMonth: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.month'),
)
const endDay: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.day'),
)
// Number e.g. 23 or 0
const startHour: Ref<string | number> = ref(0)
// Number e.g. 59 or 0
const startMinute: Ref<string | number> = ref(0)
const endHour: Ref<string | number> = ref(23)
const endMinute: Ref<string | number> = ref(59)

const checkFromDateFieldsEmpty = () =>
  typeof startYear.value !== 'number' ||
  typeof startMonth.value !== 'number' ||
  typeof startDay.value !== 'number'

const checkToDateFieldsEmpty = () =>
  typeof endYear.value !== 'number' ||
  typeof endMonth.value !== 'number' ||
  typeof endDay.value !== 'number'

const fromDate = computed(() => {
  if (checkFromDateFieldsEmpty()) {
    return dayjs('invalid date')
  } else {
    return dayjs(
      `${startYear.value}-${startMonth.value}-${startDay.value} ${
        Number(startHour.value) < 10
          ? '0' + Number(startHour.value)
          : Number(startHour.value)
      }:${
        Number(startMinute.value) < 10
          ? '0' + Number(startMinute.value)
          : Number(startMinute.value)
      }`,
      'YYYY-MM-DD HH:mm',
    )
  }
})

const toDate = computed(() => {
  if (checkToDateFieldsEmpty()) {
    return dayjs('invalid date')
  } else {
    return dayjs(
      `${endYear.value}-${endMonth.value}-${endDay.value} ${
        Number(endHour.value) < 10
          ? '0' + Number(endHour.value)
          : Number(endHour.value)
      }:${
        Number(endMinute.value) < 10
          ? '0' + Number(endMinute.value)
          : Number(endMinute.value)
      }`,
      'YYYY-MM-DD HH:mm',
    )
  }
})

const checkInvalidDates = () => {
  return !(
    fromDate.value.isValid &&
    fromDate.value.isValid() &&
    toDate.value.isValid()
  )
}
const validDates = ref(false)
const errorDate = ref(false)
watch(
  [
    startYear,
    startMonth,
    startDay,
    startHour,
    startMinute,
    endYear,
    endMonth,
    endDay,
    endHour,
    endMinute,
  ] as unknown as any[],
  (
    [
      newstartYear,
      newstartMonth,
      newstartDay,
      newstartHour,
      newstartMinute,
      newendYear,
      newendMonth,
      newendDay,
      newendHour,
      newendMinute,
    ],
    [
      oldstartYear,
      oldstartMonth,
      oldstartDay,
      oldstartHour,
      oldstartMinute,
      oldendYear,
      oldendMonth,
      oldendDay,
      oldendHour,
      oldendMinute,
    ],
  ) => {
    startYear.value = newstartYear
    startMonth.value = newstartMonth
    startDay.value = newstartDay
    startHour.value = newstartHour
    startMinute.value = newstartMinute
    endYear.value = newendYear
    endMonth.value = newendMonth
    endDay.value = newendDay
    endHour.value = newendHour
    endMinute.value = newendMinute
    verifyAndValidate()
  },
)
const verifyAndValidate = () => {
  validDates.value = !checkInvalidDates()
  errorDate.value = !!checkErrorDate()
  if (errorDate.value) {
    callVerifyVacation()
  }
}
const checkErrorDate = () => {
  const validDates = !checkInvalidDates()
  if (
    validDates &&
    (fromDate.value.isAfter(toDate.value) ||
      fromDate.value.isSame(toDate.value))
  ) {
    return t('dashboard.time-slot.dialogVacation.errorDate')
  }
  return false
}

const rdvInTheseDates = ref(false)
const verifyVacationText = ref('')
const showAlert = computed(() => {
  if (verifyVacationText.value) return true
  else return !!checkErrorDate()
})
const alertText = computed(() => {
  const errorDates = checkErrorDate()
  if (rdvInTheseDates.value)
    return t('dashboard.time-slot.dialogVacation.alert')
  else if (errorDates) return String(errorDates)
  else if (verifyVacationText.value) return verifyVacationText.value
  else return ''
})

const toggleVerifyDialog = async () => {
  await callVerifyVacation()
  ConfirmDialogService.confirm({
    title: isEditing.value
      ? t('dashboard.time-slot.dialogVacation.confirmUpdateVacation')
      : t('dashboard.time-slot.dialogVacation.confirmCreateVacation'),
    message: verifyVacationText.value,
    optionsConfirm: {
      onCancel() {},
      onConfirm() {
        handleConfirmVacation()
      },
    },
  })
}

interface VacationPostOrPutParams {
  startDate: string
  endDate: string
}

interface VerificationMessage {
  message: string
}

const httpClient = createHttpClient()

const callVerifyVacation = async () => {
  if (!checkInvalidDates()) {
    try {
      loadingConfirmBtn.value = true
      const vacation: VacationPostOrPutParams = {
        startDate: fromDate.value.toISOString(),
        endDate: toDate.value.toISOString(),
      }
      let verifyVacation: ApiResponse<VerificationMessage>
      if (isEditing.value) {
        // case edition
        try {
          verifyVacation = await httpClient.put(
            `/vacation/${vacationId.value}/verify`,
            vacation,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
        } catch (error) {
          verifyVacationText.value = error.response.data.message
        }
      } else {
        // case creation
        try {
          verifyVacation = await httpClient.post('/vacation/verify', vacation, {
            headers: { 'Content-Type': 'application/json' },
          })
          verifyVacationText.value = verifyVacation.data.message
        } catch (error) {
          verifyVacationText.value = error.response.data.message
        }
      }
    } catch (error) {
      // verifyVacationText.value = error
      // MessageService.error(t('common.error.errorHasOccurred'))
    }
    loadingConfirmBtn.value = false
  }
}

interface VacationDTOResponse extends VacationPostOrPutParams {
  id: string
}
const loadingConfirmBtn = ref(false)
const handleConfirmVacation = async () => {
  loadingConfirmBtn.value = true
  try {
    const vacation: VacationPostOrPutParams = {
      startDate: fromDate.value.toISOString(),
      endDate: toDate.value.toISOString(),
    }
    let validatedVacation: ApiResponse<VacationDTOResponse>
    if (isEditing.value) {
      // vacation edition
      validatedVacation = await httpClient.put(
        `/vacation/${vacationId.value}/validate`,
        vacation,
      )
      if (validatedVacation.status !== 200) {
        throw Error(JSON.stringify(validatedVacation))
      }
      MessageService.success(
        isEditing.value
          ? t(
              'dashboard.time-slot.dialogVacation.successVacactionValidateUpdate',
            )
          : t('dashboard.time-slot.dialogVacation.successVacactionValidate'),
      )
    } else {
      // vacation creation
      validatedVacation = await httpClient.post('/vacation/validate', vacation)

      MessageService.success(
        t('dashboard.time-slot.dialogVacation.successVacactionValidate'),
      )
    }

    await init()
  } catch (error) {
    MessageService.error(
      t('dashboard.time-slot.dialogVacation.errorVacactionValidate'),
    )
  }
  loadingConfirmBtn.value = false
  reset()
}

const loadingDelete = ref(false)
const openDeleteConfirm = () => {
  ConfirmDialogService.confirm({
    title: t('dashboard.time-slot.dialogVacation.confirmDeletionTitle'),
    message: t('dashboard.time-slot.dialogVacation.confirmDeletionMessage'),
    optionsConfirm: {
      onConfirm: () => {
        handleDeleteVacation()
      },
      onCancel: () => {},
    },
  })
}

const router = useRouter()
const init = async () => {
  const { initTimeSlots } = useTimeSlotStore()
  const { initAppointments } = usePractitionerAppointmentStore()
  if (router.currentRoute.value.name === 'DashboardTimeSlot') {
    await initTimeSlots()
  }
  if (router.currentRoute.value.name === 'DashboardAppointment') {
    await initAppointments()
  }
}

const handleDeleteVacation = async () => {
  loadingDelete.value = true
  try {
    await httpClient.delete('/vacation/' + vacationId.value)

    MessageService.success(
      t('dashboard.time-slot.dialogVacation.successDeleteVacaction'),
    )
    await init()
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
  }
  loadingDelete.value = false
  reset()
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
}
.red-icon:deep() i {
  color: red;
}
.datetime-selector {
  display: grid;
  grid-gap: 12px;
  grid-template-areas:
    'a b c c c c c c c c'
    'a b d d d d d d d d';
}
.a {
  grid-area: a;
  width: 20px;
}
.b {
  width: 20px;
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
}
</style>
