<template>
  <div class="d-flex flex-column ga-4">
    <label for="" class="custom-text-gray">Couleur du créneau</label>
    <div class="d-flex flex-wrap colors">
      <div
        v-for="color in colors"
        :key="color"
        :style="{ backgroundColor: color }"
        class="color-box"
        @click="selectColor(color)"
        :class="{
          selected: color === modelValue,
          'selected-color': modelValue == null,
        }"
      ></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps<{
  modelValue: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const colors = [
  '#4467c5',
  '#D50101',
  '#E67C73',
  '#F4511E',
  '#F6BF25',
  '#33B679',
  '#0A8043',
  '#049BE5',
  '#4050B5',
]

// Méthode pour sélectionner une couleur et émettre la valeur
const selectColor = (color: string) => {
  emit('update:modelValue', color)
}
</script>

<style scoped>
.color-picker {
  display: flex;
  gap: 10px;
  align-items: start;
  justify-content: center;
}

.color-box {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  transition: transform 0.2s;
  border: 2px solid transparent;
}

.color-box:hover {
  transform: scale(1.15);
}

.color-box.selected::before {
  content: '✔';
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.colors {
  gap: 10px;
}
</style>
