<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import CompanyDialog from '@/components/dialog/CompanyDialog.vue'

import { useI18n } from 'vue-i18n'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'

const subscriptionStore = useSubscriptionStore()

const { t: $t, d, n } = useI18n()

const fullname = computed(() => {
  if (
    subscriptionStore.userInformation &&
    subscriptionStore.userInformation.fullName
  ) {
    const { fullName } = subscriptionStore.userInformation

    return fullName
  }
})

const tvaNumber = computed(() => {
  if (
    subscriptionStore.userInformation &&
    subscriptionStore.userInformation.tvaNumberCompany
  ) {
    const { tvaNumberCompany } = subscriptionStore.userInformation

    if (tvaNumberCompany) {
      return tvaNumberCompany
    }
  }
  return $t('common.notSpecified')
})

const siret = computed(() => {
  if (
    subscriptionStore.userInformation &&
    subscriptionStore.userInformation.siret
  ) {
    const { siret } = subscriptionStore.userInformation

    if (siret) {
      return siret
    }
  }
  return $t('common.notSpecified')
})

const address = computed(() => {
  if (
    subscriptionStore.userInformation &&
    subscriptionStore.userInformation.addressCompany
  ) {
    return subscriptionStore.userInformation.addressCompany
  }
})

const postalCode = computed(() => {
  if (
    subscriptionStore.userInformation &&
    subscriptionStore.userInformation.cityCompany
  ) {
    const { countryCompany, cityCompany, postalCodeCompany } =
      subscriptionStore.userInformation

    if (countryCompany || cityCompany || postalCodeCompany) {
      return [postalCodeCompany, cityCompany, countryCompany]
        .filter(Boolean) // retire les éléments null, undedined ou false
        .join(' ')
    }
  }
})

const displayModal = ref(false)
</script>

<template>
  <div>
    <div class="mt-8 d-flex flex-column ga-3">
      <span class="text-dark-green font-weight-medium">{{
        $t('dashboard.subscription.billing_info')
      }}</span>
      <v-card
        elevation="0"
        color="white"
        border
        class="pa-sm-5 pa-2"
        rounded="xl"
      >
        <div class="d-flex flex-column ga-10 position-relative">
          <div>
            <v-row>
              <v-col cols="6">
                <div class="title text-gray-80 font-weight-bold">
                  {{ $t('dashboard.subscription.full_name') }}
                </div>
                <div class="value text-gray-80">{{ fullname }}</div>
              </v-col>
              <v-col cols="6">
                <div class="title text-gray-80 font-weight-bold">
                  {{ $t('dashboard.subscription.company_number') }}
                </div>
                <div class="value text-gray-80">{{ siret }}</div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <div class="title text-gray-80 font-weight-bold">
                  {{ $t('dashboard.subscription.address') }}
                </div>
                <div class="value" v-if="address || postalCode">
                  <div class="text-gray-80">{{ address }}</div>
                  <div class="text-gray-80">{{ postalCode }}</div>
                </div>
                <div v-else class="text-gray-80 value">
                  {{ $t('common.notSpecified') }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="title text-gray-80 font-weight-bold">
                  {{ $t('dashboard.subscription.tva_number') }}:
                </div>
                <div class="value text-gray-80">{{ tvaNumber }}</div>
              </v-col>
            </v-row>
          </div>

          <p class="description-info" style="color: #9e9e9e">
            {{ $t('dashboard.subscription.information_desc') }}
          </p>

          <img
            @click="displayModal = true"
            class="position-absolute cursor-pointer"
            style="right: 0; top: 10px"
            width="20"
            height="20"
            src="@/assets/edit.png"
          />
        </div>
      </v-card>
    </div>

    <CompanyDialog
      v-if="displayModal"
      :dialog="displayModal"
      @update:close="displayModal = false"
    />
  </div>
</template>

<style scoped lang="scss">
.description-info {
  font-size: 14px;
}
.title {
  font-size: 16px;
}

.value {
  font-size: 14px;
}

@media (max-width: 599px) {
  .title {
    font-size: 14px;
  }

  .value {
    font-size: 12px;
  }

  .description-info {
    font-size: 12px;
  }
}
</style>
