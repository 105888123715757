<template>
  <VRow align="center" no-gutters>
    <v-menu v-model="dayMenuOpen" transition="scale-transition">
      <template v-slot:activator="{ props }">
        <div
          class="select-day d-flex align-center pl-2 pr-2 py-1 cursor-pointer"
          v-bind="props"
        >
          {{ selectedDay }}
          <v-icon
            class="transition-smooth"
            :class="{ 'rotated-angle': dayMenuOpen }"
            color="black"
            icon="mdi-chevron-down"
          />
        </div>
      </template>
      <v-list density="compact" max-height="300">
        <v-list-item
          :value="day"
          v-for="day in dayList"
          :key="`day-${day}`"
          @click="handleSelectDay(day)"
        >
          <v-list-item-title>{{ day }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-model="monthMenuOpen" transition="scale-transition">
      <template v-slot:activator="{ props }">
        <div
          class="select-month text-capitalize d-flex align-center pl-2 pr-1 py-1 cursor-pointer"
          v-bind="props"
        >
          {{ monthList[Number(selectedMonth) - 1]?.title || selectedMonth }}
          <v-icon
            class="transition-smooth"
            :class="{ 'rotated-angle': monthMenuOpen }"
            color="black"
            icon="mdi-chevron-down"
          />
        </div>
      </template>
      <v-list density="compact" max-height="300">
        <v-list-item
          :value="month.value"
          v-for="month in monthList"
          :key="month.title"
          @click="handleSelectMonth(month)"
        >
          <v-list-item-title class="text-capitalize">
            {{ month.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-model="yearMenuOpen" transition="scale-transition">
      <template v-slot:activator="{ props }">
        <div
          class="select-year d-flex align-center pl-2 pr-1 py-1 cursor-pointer"
          v-bind="props"
        >
          {{ selectedYear }}
          <v-icon
            class="transition-smooth"
            :class="{ 'rotated-angle': yearMenuOpen }"
            color="black"
            icon="mdi-chevron-down"
          />
        </div>
      </template>
      <v-list density="compact" v-model="selectedYear" max-height="300">
        <v-list-item
          :value="year"
          v-for="year in yearList"
          :key="year"
          @click="handleSelectYear(year)"
        >
          <v-list-item-title>
            {{ year }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </VRow>
</template>
<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue'
import dayjs from 'dayjs'

const selectedYear: Ref<string | number> = defineModel('year')
const selectedMonth: Ref<string | number> = defineModel('month')
const selectedDay: Ref<string | number> = defineModel('day')

const currentYear = dayjs().year()

const dayList: Ref<number[]> = computed(() => {
  if (typeof selectedMonth.value === 'number') {
    const year =
      typeof selectedYear.value === 'number' ? selectedYear.value : currentYear
    const daysInMonth = dayjs(
      `${year}-${selectedMonth.value < 10 ? '0' + String(selectedMonth.value) : selectedMonth.value}-02`,
      'YYYY-MM-DD',
    ).daysInMonth()
    return Array.from({ length: daysInMonth }, (_, i) => i + 1)
  } else return Array.from({ length: 31 }, (_, i) => i + 1)
})

const dayMenuOpen = ref(false)
const handleSelectDay = (day: number) => {
  selectedDay.value = day
}

const monthList = Array.from({ length: 12 }, (_, i) => ({
  title: dayjs().month(i).format('MMMM'),
  value: i,
}))
const monthMenuOpen = ref(false)
const handleSelectMonth = month => {
  selectedMonth.value = month.value + 1
}
watch(selectedMonth, () => {
  correctDaySelected()
})

const yearList = Array.from({ length: 30 }, (_, i) => i + currentYear)
const yearMenuOpen = ref(false)
const handleSelectYear = year => {
  selectedYear.value = year
}
watch(selectedYear, () => {
  correctDaySelected()
})

const correctDaySelected = () => {
  if (
    typeof selectedYear.value === 'number' &&
    typeof selectedMonth.value === 'number'
  ) {
    const daysInMonth = dayjs(
      `${selectedYear.value}-${selectedMonth.value < 10 ? '0' + String(selectedMonth.value) : selectedMonth.value}-02`,
      'YYYY-MM-DD',
    ).daysInMonth()
    dayList.value = Array.from({ length: daysInMonth }, (_, i) => i + 1)
    if (
      typeof selectedDay.value === 'number' &&
      selectedDay.value > dayList.value[dayList.value.length - 1]
    ) {
      selectedDay.value = dayList.value[dayList.value.length - 1]
    }
  }
}
</script>
<style scoped>
@import url('./style.css');

.select-year {
  width: 103px;
  border: 1px solid #aaa;
  border-radius: 0 100px 100px 0 !important;
  background-color: #fff;
  position: relative;
  border-left: none;
  display: flex;
  justify-content: space-around;
  &:hover {
    background-color: #f8f8f8;
  }
}
.select-month {
  min-width: 93px;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  border-radius: 0 !important;
  background-color: #fff;
  position: relative;
  border-left: none;
  display: flex;
  justify-content: space-around;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #aaa;
  }
  &:hover {
    background-color: #f8f8f8;
  }
}

.select-day {
  width: 93px;
  display: flex;
  justify-content: center;
  border: 1px solid #aaa;
  border-radius: 100px 0 0 100px !important;
  background-color: #fff;
  position: relative;
  border-right: none;
  & .v-icon {
    position: absolute;
    right: 4px;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #aaa;
  }
  &:hover {
    background-color: #f8f8f8;
  }
}
@media screen and (max-width: 380px) {
  .select-day,
  .select-month,
  .select-year {
    width: 75px;
    font-size: small;
  }
}
.select-profile-option-wrapper {
  position: absolute;
  top: 117px;
  z-index: 999;
  & .select-profile-option {
    background-color: #f4f4f4;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
}
</style>
