import { AxiosResponse } from 'axios'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api.d'
import { TvaResponse } from './tva.d'

const httpClient = createHttpClient()

export const fetchUserTva = async (): Promise<ApiResponse<TvaResponse>> => {
  const response: AxiosResponse<TvaResponse> =
    await httpClient.get<TvaResponse>('/account/price')

  return {
    status: response.status,
    data: response.data,
  }
}
