// start from 0 for sunday
// The order count for the dialog time slot to display the week days starting from monday
export enum EnumDays {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}
