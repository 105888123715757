import { Ref, computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import { ProfileInformationData } from '@/api/profile/profile.d'
import { AddressInformationData } from '@/api/account/address'
import { useStorage } from '@vueuse/core'
import { useUserStore } from '../user/userStore'
import { TimeSlotEvent } from './timeSlotEvent.d'
import { FormattedAppointmentReasonInformationData } from '@/api/appointment-reason/appointment-reason.d'
import { EnumDays } from '@/shared/enumDays'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import { useAddressStore } from '@/store/address/addressStore'
import { useProfileStore } from '../profile/profileStore'

const DEFAULT_TIMESLOT_DURATION = 4
const DEFAULT_TIMESLOT_UNIT = 'h'

export const useDialogTimeSlotStore = defineStore('dialog-timeslot', () => {
  const isEditing: Ref<boolean> = ref(false)
  const timeSlotId: Ref<string> = ref(null)
  const isDialogTimeSlotOpen: Ref<boolean> = ref(false)
  const isDialogTimeSlotConflictOpen: Ref<boolean> = ref(true)
  const selectedProfile: Ref<ProfileInformationData> = ref(null)
  const selectedAddress: Ref<AddressInformationData> = ref(null)
  const color: Ref<string> = ref('#4467c5')
  const selectedProfiles: Ref<ProfileInformationData[]> = ref([])
  const selectedAddresses: Ref<AddressInformationData[]> = ref([])
  const addressStore = useAddressStore()
  const profileStore = useProfileStore()

  const isConflictModalOpen: Ref<boolean> = ref(false)
  const conflictTimeslot = ref(null)
  const isCurrentTimeslotRepeat: Ref<'yes' | 'no'> = ref('no')

  const closeConflictModal = () => {
    isConflictModalOpen.value = false

    if (isCurrentTimeslotRepeat.value == 'yes') {
      repeatTimeSlot.value = 'yes'
    } else {
      repeatTimeSlot.value = 'no'
    }
  }

  const deleteConflictModal = () => {
    conflictTimeslot.value = null
  }

  const dialogTimeSlotCreationProfileAddress = useStorage(
    'dialogTimeSlotCreationProfileAddress',
    {} as
      | {
          address: AddressInformationData
          profile: ProfileInformationData
          profiles: ProfileInformationData[]
          addresses: AddressInformationData[]
        }
      | undefined
      | null,
  )
  const saveToLocalStorage = (
    profile: ProfileInformationData,
    address: AddressInformationData,
    profiles: ProfileInformationData[],
    addresses: AddressInformationData[],
  ) => {
    dialogTimeSlotCreationProfileAddress.value = {
      profile,
      address,
      profiles,
      addresses,
    }
  }

  const selectedDate: Ref<string> = ref(dayjs().format('YYYY-MM-DD'))
  const selectedDateEnd: Ref<string> = ref(
    dayjs().add(1, 'month').format('YYYY-MM-DD'),
  )
  const isIndefinite = ref(false)

  const selectedStartTime: Ref<string | 'HH:mm'> = ref(
    dayjs().format('YYYY-MM-DD HH:mm').split(' ')[1],
  )
  const selectedEndTime: Ref<string | 'HH:mm'> = ref(
    dayjs()
      .add(DEFAULT_TIMESLOT_DURATION, DEFAULT_TIMESLOT_UNIT)
      .format('YYYY-MM-DD HH:mm')
      .split(' ')[1],
  )
  const selectedAppointmentReasons = ref<
    null | FormattedAppointmentReasonInformationData[]
  >(null)
  const acceptRemote: Ref<'remote' | 'in-person' | 'both'> = ref('in-person')
  const selectedDays = ref<EnumDays[]>([])
  const repeatTimeSlot: Ref<'yes' | 'no'> = ref('no')
  const hasOnlyPresentialReasons = computed<boolean>(() => {
    return selectedAppointmentReasons.value.every(
      reason => 'no' === reason.remote,
    )
  })

  const openConflictModal = (timeslotData: any) => {
    console.log('here')
    timeSlotId.value = timeslotData.id

    isConflictModalOpen.value = true
    isCurrentTimeslotRepeat.value = repeatTimeSlot.value
    console.log(isConflictModalOpen.value)
    conflictTimeslot.value = timeslotData
    if (conflictTimeslot.value.repeat == true) {
      repeatTimeSlot.value = 'yes'
    } else {
      repeatTimeSlot.value = 'no'
    }
    console.log(conflictTimeslot.value)
  }

  const hasErrorDate = computed(() => {
    const start = dayjs(
      `${selectedDate.value} ${selectedStartTime.value}`,
      'YYYY-MM-DD HH:mm',
    )
    const end = dayjs(
      `${selectedDate.value} ${selectedEndTime.value}`,
      'YYYY-MM-DD HH:mm',
    )

    return end.isBefore(start) || end.isSame(start)
  })

  const formValid = computed(() => {
    const start = dayjs(
      `${selectedDate.value} ${selectedStartTime.value}`,
      'YYYY-MM-DD HH:mm',
    )
    const end = dayjs(
      `${selectedDate.value} ${selectedEndTime.value}`,
      'YYYY-MM-DD HH:mm',
    )

    if (selectedProfiles.value) {
      if (0 < selectedProfiles.value.length) {
        selectedProfile.value = selectedProfiles.value[0]
      }
    }

    const hasError = end.isBefore(start) || end.isSame(start)
    if (hasError) return false
    if (
      selectedAddress.value &&
      0 < selectedProfiles.value.length &&
      selectedAppointmentReasons.value &&
      0 < selectedAppointmentReasons.value.length &&
      selectedDate.value &&
      acceptRemote.value
    )
      return true
    return false
  })

  const isRemote = computed(
    () => acceptRemote.value == 'remote' || acceptRemote.value == 'both',
  )
  const isPresential = computed(
    () => acceptRemote.value == 'in-person' || acceptRemote.value == 'both',
  )

  const resetDialogTimeSlotCreationProfileAddress = async () => {
    if (addressStore.addresses.length > 0 && profileStore.profiles.length > 0) {
      if (addressStore.addresses.length > 0) {
        dialogTimeSlotCreationProfileAddress.value.address =
          addressStore.addresses[0]

        dialogTimeSlotCreationProfileAddress.value.addresses =
          addressStore.addresses
      }
      if (profileStore.profiles.length > 0) {
        dialogTimeSlotCreationProfileAddress.value.profile =
          profileStore.profiles[0]
        dialogTimeSlotCreationProfileAddress.value.profiles =
          profileStore.profiles
      }
    } else {
      dialogTimeSlotCreationProfileAddress.value = null
    }
  }

  const onOpen = async () => {
    const appointmentReasonStore = useAppointmentReasonStore()
    await appointmentReasonStore.fillAppointmentReasons()
    if (!isEditing.value) {
      selectedAppointmentReasons.value = null
      const userStore = useUserStore()
      const profileStore = useProfileStore()
      // Pre-select the first address and the first profile
      if (
        !dialogTimeSlotCreationProfileAddress.value.address &&
        !dialogTimeSlotCreationProfileAddress.value.profile
      ) {
        if (addressStore.addresses.length > 0) {
          dialogTimeSlotCreationProfileAddress.value.address =
            addressStore.addresses[0]
        }
        if (profileStore.profiles.length > 0) {
          dialogTimeSlotCreationProfileAddress.value.profile =
            profileStore.profiles[0]
        }
      }
      selectedAddress.value = dialogTimeSlotCreationProfileAddress.value.address
      if (selectedAddress.value.remote) {
        acceptRemote.value = 'remote'
      }

      selectedProfile.value = dialogTimeSlotCreationProfileAddress.value.profile
      color.value = '#4467c5'

      selectedDateEnd.value = dayjs(selectedDate.value)
        .add(1, 'month')
        .format('YYYY-MM-DD')
    }
  }

  /** start, end eg: 2024-12-12 09:00 */
  const openDialogWithDateTime = (start: string, end: string) => {
    selectedDate.value = start.split(' ')[0]
    selectedStartTime.value = start.split(' ')[1]
    selectedEndTime.value = end.split(' ')[1]
    isDialogTimeSlotOpen.value = true
    onOpen()
  }

  const toggleDialogTimeSlot = () => {
    const today = dayjs().format('YYYY-MM-DD HH:mm')
    selectedDate.value = today.split(' ')[0]
    selectedStartTime.value = today.split(' ')[1]
    selectedEndTime.value = dayjs()
      .add(DEFAULT_TIMESLOT_DURATION, DEFAULT_TIMESLOT_UNIT)
      .format('HH:mm')
    if (isDialogTimeSlotOpen.value) {
      resetData()
    } else {
      onOpen()
    }
    isDialogTimeSlotOpen.value = !isDialogTimeSlotOpen.value
  }

  const toggleDialogTimeSlotConflict = () => {
    if (isDialogTimeSlotOpen.value) {
      resetData()
    } else {
      onOpen()
    }
    isDialogTimeSlotOpen.value = !isDialogTimeSlotOpen.value
  }

  const resetData = () => {
    timeSlotId.value = null
    isEditing.value = false
    selectedProfile.value = null
    selectedAddress.value = null
    color.value = '#4467c5'
    selectedDate.value = dayjs().format('YYYY-MM-DD HH:mm')
    selectedDateEnd.value = dayjs(selectedDate.value)
      .add(1, 'month')
      .format('YYYY-MM-DD')
    selectedStartTime.value = dayjs().format('HH:mm')
    selectedEndTime.value = dayjs()
      .add(DEFAULT_TIMESLOT_DURATION, DEFAULT_TIMESLOT_UNIT)
      .format('HH:mm')
    selectedAppointmentReasons.value = null
    acceptRemote.value = 'in-person'
    selectedDays.value = []
    repeatTimeSlot.value = 'no'
  }

  const openDialogWithEvent = (calendarEvent: TimeSlotEvent) => {
    console.log('my test')
    const userStore = useUserStore()

    const profile = profileStore.profiles.find(
      p => p.id === calendarEvent.profileId,
    )

    const address = addressStore.addresses.find(
      a => a.id === calendarEvent.addressId,
    )

    console.log(calendarEvent.acceptRemote)

    timeSlotId.value = calendarEvent.timeSlotId
    selectedProfile.value = profile
    selectedAddress.value = address
    selectedProfiles.value = profile ? [profile] : calendarEvent.profiles
    selectedAddresses.value = [address]
    isEditing.value = true
    selectedDate.value = calendarEvent.start.split(' ')[0]
    selectedDateEnd.value = calendarEvent.dateEnd
    selectedStartTime.value = calendarEvent.start.split(' ')[1]
    selectedEndTime.value = calendarEvent.end.split(' ')[1]
    selectedAppointmentReasons.value = calendarEvent.appointmentReasons ?? null

    isIndefinite.value = calendarEvent.dateEnd ? false : true

    acceptRemote.value = calendarEvent.acceptRemote
    repeatTimeSlot.value = calendarEvent.weekRepeat
    selectedDays.value = calendarEvent.days
    color.value = calendarEvent.color ? calendarEvent.color : '#4467c5'
    openDialogWithDateTime(calendarEvent.start, calendarEvent.end)
  }
  watch(isIndefinite, newVal => {
    if (newVal) {
      selectedDateEnd.value = null // Si "Indéfiniment" est coché, la date est rendue à null
    } else {
      if (!isEditing.value) {
        selectedDateEnd.value = dayjs(selectedDate.value)
          .add(1, 'month')
          .format('YYYY-MM-DD') // Si "Indéfiniment" est décoché, remettre une date par défaut
      } else {
        if (
          selectedDateEnd.value == null ||
          selectedDateEnd.value == undefined
        ) {
          selectedDateEnd.value = dayjs(selectedDate.value)
            .add(1, 'month')
            .format('YYYY-MM-DD')
        }
      }
    }
  })

  watch(selectedAddresses, newVal => {
    if (newVal) {
      selectedAddress.value = selectedAddresses.value[0]
      if (selectedAddress.value.remote) {
        acceptRemote.value = 'remote'
      }
    }
  })

  watch(repeatTimeSlot, newVal => {
    if (newVal) {
      if (newVal == 'no') {
        selectedDays.value = []
        selectedDateEnd.value = null
      } else {
        if (!isEditing.value) {
          selectedDateEnd.value = dayjs(selectedDate.value)
            .add(1, 'month')
            .format('YYYY-MM-DD')
        }
      }
    }
  })

  const everyXWeek = ref<number>(1)

  return {
    timeSlotId,
    dialogTimeSlotCreationProfileAddress,
    isDialogTimeSlotOpen,
    saveToLocalStorage,
    hasErrorDate,
    isEditing,
    selectedProfile,
    selectedAddress,
    selectedProfiles,
    selectedAddresses,
    selectedDate,
    selectedDateEnd,
    isIndefinite,
    selectedStartTime,
    selectedEndTime,
    color,
    selectedAppointmentReasons,
    hasOnlyPresentialReasons,
    acceptRemote,
    selectedDays,
    onOpen,
    formValid,
    repeatTimeSlot,
    isRemote,
    isPresential,
    openDialogWithDateTime,
    openDialogWithEvent,
    resetData,
    toggleDialogTimeSlot,
    everyXWeek,
    resetDialogTimeSlotCreationProfileAddress,
    isConflictModalOpen,
    conflictTimeslot,
    openConflictModal,
    closeConflictModal,
    deleteConflictModal,
    isCurrentTimeslotRepeat,
  }
})
