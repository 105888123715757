<script lang="ts" setup>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker, LTooltip } from '@vue-leaflet/vue-leaflet'
import { onMounted, ref, watch, nextTick } from 'vue'

const url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
const attribution =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'

const props = defineProps({
  latitude: {
    type: String,
    default: '',
  },
  longitude: {
    type: String,
    default: '',
  },
  mapcountry: {
    type: String,
    default: '',
  },
  searchQuery: {
    type: String,
    default: '',
  },
  not_yet: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:position', 'streetLoaading'])

const currentZoom = ref(11.5)
const currentCenter = ref([
  parseFloat(props.latitude) || 0,
  parseFloat(props.longitude) || 0,
])

const mapOptions = {
  zoomSnap: 0.5,
}

const zoomUpdate = zoom => {
  currentZoom.value = zoom
}

const centerUpdate = async center => {
  if (!props.not_yet) return

  currentCenter.value = center
  await nextTick()
}

const onMapClick = event => {
  if (!props.not_yet) return
  const { lat, lng } = event.latlng

  currentCenter.value = event.latlng

  emit('update:position', { latitude: lat, longitude: lng })
}

// Fonction pour obtenir les coordonnées d'un pays via Nominatim
const fetchCountryCoordinates = async (countryName: string) => {
  if (!props.not_yet) return

  try {
    emit('streetLoaading', { value: true })

    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?street=${encodeURIComponent(props.searchQuery)}&country=${countryName}&format=json&limit=1`,
    )

    const data = await response.json()

    if (data.length > 0) {
      const { lat, lon } = data[0]
      currentCenter.value = [parseFloat(lat), parseFloat(lon)]
      emit('update:position', { latitude: lat, longitude: lon })
      await nextTick()
    } else {
      console.error('.')
    }
  } catch (error) {
    console.error('.')
  } finally {
    emit('streetLoaading', { value: false })
  }
}

watch(
  () => [props.latitude, props.longitude],
  async () => {
    currentCenter.value = [
      parseFloat(props.latitude),
      parseFloat(props.longitude),
    ]
    // await nextTick()
  },
)

watch(
  () => [props.mapcountry, props.searchQuery],
  () => {
    if (props.mapcountry) {
      fetchCountryCoordinates(props.mapcountry)
    }
  },
  { immediate: true, deep: true },
)

// onMounted(() => {
//   console.log('onmouted currentCenter', currentCenter.value)
// })
</script>

<template>
  <div style="height: 500px; width: 100%">
    <l-map
      :zoom="currentZoom"
      :center="currentCenter"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @click="onMapClick"
    >
      <l-marker :lat-lng="currentCenter"> </l-marker>
      <l-tile-layer layer-type="base" :url="url" :attribution="attribution" />
    </l-map>
  </div>
</template>
