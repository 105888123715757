<template>
  <div class="d-flex justify-center">
    <div
      v-for="(error, index) in errorList"
      :key="index"
      class="text-error text-caption"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  errors: [Array, String],
})

const errorList = computed(() => {
  return typeof props.errors === 'string' ? [props.errors] : props.errors
})
</script>

<style scoped lang="scss"></style>
