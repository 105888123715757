import dayjs from 'dayjs'

export type AvailabilityAppointment = {
  start: string
  reasonId: string
  remote: boolean
  presential: boolean
}

export type AugmentedMeetingSlot = {
  date: string // !! must be YYYY-MM-DD HH:mm:ss
  appointmentReasonId: string
  remote?: 'in-person' | 'remote'
  start: string
  [key: string]: any
}

export type AugmentedMeetingsDay = {
  date: string // !! must be YYYY-MM-DD HH:mm:ss
  slots: AugmentedMeetingSlot[]
  [key: string]: any
}

/** Function to get all the unique days */
export function getAvailableDays(data: AvailabilityAppointment[]): string[] {
  const daysSet = new Set<string>()

  data.forEach(appointment => {
    const startDay = dayjs(appointment.start).format('YYYY-MM-DD')
    daysSet.add(startDay)
  })

  return Array.from(daysSet).sort()
}

/** Function to get all the slots formatted */
export function getAllSlots(
  data: AvailabilityAppointment[],
): AugmentedMeetingSlot[] {
  const slots: AugmentedMeetingSlot[] = []

  data.forEach(appointment => {
    // const date = new Date(appointment.start).toISOString() //.split(' ')[0]
    const date = appointment.start //.split(' ')[0]
    slots.push({
      date,
      start: appointment.start,
      appointmentReasonId: appointment.reasonId,
      remote:
        appointment.remote && !appointment.presential ? 'remote' : 'in-person',
    })
  })

  return slots
}

export function transformData(
  data: AvailabilityAppointment[],
): AugmentedMeetingsDay[] {
  if (0 === data.length) return []
  const transformedData: AugmentedMeetingsDay[] = []

  const uniqueDates = getAvailableDays(data)
  const allSlots = getAllSlots(data)
  // create slots by date and add to the array
  uniqueDates.forEach(date => {
    const slotsForDate = allSlots.filter(slot => {
      return dayjs(slot.date).format('YYYY-MM-DD') === date
    })
    transformedData.push({
      date,
      slots: slotsForDate,
    })
  })

  return transformedData
}

type Appointment = {
  start: string
  end: string
  price: number
  duration: number
  reason: string
}

export type AvailableData = {
  [key: string]: Appointment[]
}

/** Function to get all the unique days */
export function getAvailableDaysFromAvailableData(
  data: AvailableData,
): string[] {
  const daysSet = new Set<string>()

  Object.values(data).forEach(appointments => {
    appointments.forEach(appointment => {
      const startDay = new Date(appointment.start).toISOString().split('T')[0]
      // const endDay = appointment.end.split('T')[0] // it shouldn't be different from start date in reality
      daysSet.add(startDay)
      // daysSet.add(endDay)
    })
  })

  return Array.from(daysSet).sort()
}

/** Function to get all the slots formatted */
export function getAllSlotsFromAvailableData(
  data: AvailableData,
): AugmentedMeetingSlot[] {
  const slots: AugmentedMeetingSlot[] = []

  Object.values(data).forEach(appointments => {
    appointments.forEach(appointment => {
      const date = new Date(appointment.start).toISOString() //.split(' ')[0]
      slots.push({
        date,
        start: appointment.start,
        end: appointment.end,
        appointmentReasonId: appointment.reason,
        price: appointment.price,
        duration: appointment.duration,
      })
    })
  })

  return slots
}

export function transformDataFromAvailableData(
  data: AvailableData,
): AugmentedMeetingsDay[] {
  const transformedData: AugmentedMeetingsDay[] = []

  const uniqueDates = getAvailableDaysFromAvailableData(data)
  const allSlots = getAllSlotsFromAvailableData(data)
  // create slots by date and add to the array
  uniqueDates.forEach(date => {
    const slotsForDate = allSlots
      .filter(slot => {
        return dayjs(slot.date).format('YYYY-MM-DD') === date
      })
      .sort((a, b) => {
        if (b.date > a.date) {
          return -1
        } else if (a.date > b.date) {
          return 1
        } else if (a.data === b.date) {
          return 0
        }
      })
    transformedData.push({
      date,
      slots: slotsForDate,
    })
  })

  return transformedData
}

/**
 *
 * @param data
 * @param date YYYY-MM-DD
 * @returns
 */
function getSlotsOfDate(data: AugmentedMeetingsDay[], date: string) {
  const meetingsDayItem = data.find(({ date: dateOfItem }) => {
    return dateOfItem.includes(date)
  })
  if (meetingsDayItem) return meetingsDayItem.slots
  return [{}]
}

export function addMissingDays(
  data: AugmentedMeetingsDay[],
  start: dayjs.Dayjs,
  numberOfDays: number,
): AugmentedMeetingsDay[] {
  const meetingsDays = []
  for (let i = 0; i < numberOfDays + 1; i++) {
    const date = dayjs(start).add(i, 'days').format('YYYY-MM-DD')

    meetingsDays.push({
      date,
      slots: getSlotsOfDate(data, date),
    })
  }
  return meetingsDays
}
