import i18n from '@/plugins/i18n'
const { t } = i18n.global
const paymentMethodsDic = {
  cash: t('practitionerPublicProfile.cash'),
  check: t('practitionerPublicProfile.check'),
  creditCard: t('practitionerPublicProfile.creditCard'),
  transfer: t('practitionerPublicProfile.transfer'),
}
export function formatPaymentMethods(paymentMethods) {
  return paymentMethods.map(pm => paymentMethodsDic[pm]).join(', ')
}
