import EventBus from '@/utils/eventBus'
import { IConfirmParams } from '@/components/feedback/confirmDialog/confirmDialog.d'

const ConfirmDialogService = {
  confirm: (confirmParams: IConfirmParams) => {
    EventBus.emit('confirm', confirmParams)
  },
  close: () => {
    EventBus.emit('close-confirm')
  },
}

export default ConfirmDialogService
