<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import WarningIcon from '@/components/icons/WarningIcon.vue'

const { t } = useI18n()

const { dialog } = defineProps({
  dialog: Boolean,
})

const emit = defineEmits(['update:leave', 'update:cancel'])
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title
        class="d-flex justify-center w-full align-center bg-surface-lighten-1"
      >
        <span class="font-weight-bold title">
          {{ t('profileForm.alertTitle') }}
        </span>
      </v-card-title>

      <v-card-text class="pa-5">
        <div class="d-flex flex-column ga-3">
          <div class="d-flex justify-center">
            <WarningIcon color="#FF5353" />
          </div>
          <p>{{ t('profileForm.alertDescription') }}</p>

          <div class="d-flex justify-end ga-2">
            <v-btn @click="emit('update:leave')" color="#FF5353">{{
              t('profileForm.leave')
            }}</v-btn>
            <v-btn @click="emit('update:cancel')" color="secondary">{{
              t('profileForm.noLeave')
            }}</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.title {
  color: #007058;
  font-weight: 600px;
  font-size: 20px;
  line-height: 32.2px;
}

@media (max-width: 599px) {
  .title {
    font-size: 15px;
  }
}
</style>
