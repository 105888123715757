<template>
  <div
    class="rich-editor"
    :class="{ focus: focused, 'has-error': errorMessages.length > 0 }"
  >
    <div
      class="text-caption ml-3"
      :class="{
        'text-secondary': focused,
        'text-error': errorMessages.length,
        'text-medium-emphasis': !focused && !errorMessages.length,
      }"
    >
      {{ label }}
    </div>
    <quill-editor
      ref="editor"
      v-bind="$attrs"
      color="secondary"
      theme="snow"
      :toolbar="toolbar"
      :modules="modules"
      :options="{ debug: 'error' }"
      @focus="focused = true"
      @blur="handleBlur"
      @textChange="onInput"
    >
    </quill-editor>
    <div class="d-flex">
      <div class="flex-grow-1">
        <div
          v-if="errorMessages.length > 0"
          class="text-caption text-error ml-3"
        >
          {{ errorMessages[0] }}
        </div>
        <div v-else class="text-caption text-medium-emphasis ml-3">
          {{ hint }}
        </div>
      </div>
      <div
        v-if="props.counter"
        class="text-caption text-medium-emphasis mr-3 flex-shrink-0"
      >
        {{ editorContent.length }}
        <span v-if="typeof counter === 'number'"> / {{ counter }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Delta, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { ref } from 'vue'
import Clipboard from 'quill/modules/clipboard'

const props = defineProps({
  label: String,
  hint: String,
  rules: {
    type: Array,
    default: () => [],
  },
  counter: Number || Boolean,
  toolbar: {
    type: Array,
    default: () => [
      ['bold', 'italic'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  },
})

const emit = defineEmits(['is-valid'])

function matchBold(node, delta) {
  if (node.tagName === 'B' || node.tagName === 'STRONG') {
    return delta.compose(new Delta().retain(delta.length(), { bold: true }))
  }
  return delta
}

// Custom matcher pour les noeuds texte
function matchText(node, delta) {
  if (node.nodeType === Node.TEXT_NODE) {
    return new Delta().insert(node.data)
  }
  return delta
}

function matchItalic(node, delta) {
  if (node.tagName === 'I' || node.tagName === 'EM') {
    return delta.compose(new Delta().retain(delta.length(), { italic: true }))
  }
  return delta
}

function matchList(node, delta) {
  if (node.tagName === 'UL') {
    return delta.compose(new Delta().retain(delta.length(), { list: 'bullet' }))
  } else if (node.tagName === 'OL') {
    return delta.compose(
      new Delta().retain(delta.length(), { list: 'ordered' }),
    )
  }
  return delta
}

const modules = {
  name: 'clipboard',
  module: Clipboard,
  options: {
    matchers: [
      ['B', matchBold],
      ['STRONG', matchBold],
      ['I', matchItalic],
      ['EM', matchItalic],
      ['UL', matchList],
      ['OL', matchList],
      [Node.TEXT_NODE, matchText],
    ],
  },
}

const focused = ref(false)
const errorMessages = ref([])
const editorContent = ref('')
const editor = ref<typeof QuillEditor | null>(null)

const getErrorMessages = (value: string) => {
  return props.rules
    .map(rule => (typeof rule === 'function' ? rule(value) : true))
    .filter(message => message !== true)
}

const validate = (value: string) => {
  errorMessages.value = getErrorMessages(value)
  emit('is-valid', errorMessages.value.length === 0)
  return errorMessages.value.length === 0
}

const onInput = () => {
  const text = editor.value?.getText()?.trim() || ''
  editorContent.value = text
  validate(text)
}

const isValid = () => {
  return validate(editorContent.value)
}

const handleBlur = () => {
  focused.value = false
  validate(editorContent.value)
}

defineExpose({
  isValid,
})
</script>

<style scoped lang="scss">
@use '@/styles/settings' as settings;

:deep {
  // Définition des variables
  $border-color-default: #999999; // Couleur de bordure par défaut
  $border-color-focus: rgba(
    var(--v-theme-secondary)
  ); // Couleur de bordure lors du focus
  $border-color-error: rgba(
    var(--v-theme-error)
  ); // Couleur de bordure en cas d'erreur
  $border-radius-top: 5px; // Rayon de bordure pour les coins supérieurs
  $border-radius-bottom: 5px; // Rayon de bordure pour les coins inférieurs

  .ql-toolbar {
    box-sizing: border-box;
    border: 1px solid $border-color-default;
    border-top-left-radius: $border-radius-top !important;
    border-top-right-radius: $border-radius-top !important;
  }

  .ql-container {
    box-sizing: border-box;
    border: 1px solid $border-color-default;
    border-bottom-left-radius: $border-radius-bottom !important;
    border-bottom-right-radius: $border-radius-bottom !important;
    border-top: none;
    font-size: settings.$input-font-size;
    font-family: inherit;

    .ql-editor {
      min-height: 100px;
    }
  }

  &.focus .ql-toolbar,
  &.focus .ql-container {
    border-color: $border-color-focus;
    box-shadow: 0 0 0 1px $border-color-focus;
  }

  &.has-error .ql-toolbar,
  &.has-error .ql-container {
    border-color: $border-color-error;
    box-shadow: 0 0 0 1px $border-color-error;
  }
}
</style>
