// stores/googleMaps.js
import { defineStore } from 'pinia'
import {
  getCityFromSuggestion,
  getCountryFromSuggestion,
  getPostalCodeFromSuggestion,
  GoogleMapsService,
} from '../../utils/google-maps.service'
import { ref } from 'vue'
import { FormattedAddressItem } from '@/components/forms/addressForm/addressUtils'

export const useGoogleMapsStore = defineStore('googleMaps', () => {
  const googleMapsService = ref()
  const RefPlace = ref()

  function initialize(apiKey) {
    googleMapsService.value = new GoogleMapsService(apiKey)
  }

  async function getFormatedAddressFromPlace(placePredictionId) {
    // Use place ID to create a new Place instance.
    const Place = RefPlace.value
    const place = new Place({
      id: placePredictionId,
      requestedLanguage: 'fr', // optional
    })

    await place.fetchFields({
      fields: [
        'displayName',
        'formattedAddress',
        'location',
        'addressComponents',
      ],
    })

    const city = getCityFromSuggestion(place)
    const postalCode = getPostalCodeFromSuggestion(place)
    const country = getCountryFromSuggestion(place)

    const addressItem: FormattedAddressItem = {
      addressLine: place.displayName,
      latitude: String(place.location?.lat()),
      longitude: String(place.location?.lng()),
      country,
      postalCode,
      city,
      formattedAddress: place.formattedAddress,
    }

    return addressItem
  }

  /**
   *
   * @param text
   * @param regions
   * @returns Promise<{ label: string; value: any }[]> with value: the suggestion.placePrediction object
   */
  async function getAutocompleteSuggestionsPlaces(
    text: string,
    regions: string[],
  ): Promise<{ label: string; value: any }[]> {
    const { AutocompleteSessionToken, AutocompleteSuggestion, Place } =
      await googleMapsService.value.initPlacesLibrary()

    RefPlace.value = Place

    const request = {
      input: text,
      language: 'fr-FR',
      region: 'fr',
      includedRegionCodes: regions,
    }

    const token = new AutocompleteSessionToken()
    // @ts-ignore
    request.sessionToken = token

    const { suggestions } =
      await AutocompleteSuggestion.fetchAutocompleteSuggestions(request)

    const placesSuggested = []
    for (const suggestion of suggestions) {
      const placePrediction = suggestion.placePrediction

      placesSuggested.push({
        label: placePrediction.text.toString(),
        value: placePrediction.toPlace().id,
      })
    }

    return placesSuggested
  }

  return {
    googleMapsService,
    initialize,
    getFormatedAddressFromPlace,
    getAutocompleteSuggestionsPlaces,
  }
})
