import { AddressInformationData } from '@/api/account/address.d'

export const formatReceivedAddresses = (data: AddressInformationData[]) => {
  const formattedAddresses = [...data]
  return formattedAddresses.map(address => {
    const newItem = { ...address }
    // if (newItem.hasOwnProperty('additionalInstructions')) {
    //   newItem.additionalInformations = newItem.additionalInstructions
    //   delete newItem.additionalInstructions
    // }
    return newItem
  })
}

export interface AddressItem {
  country: string | null
  addressLine: string | null
  postalCode: string | null
  city: string | null
  latitude?: string
  longitude?: string
  additionalInstructions?: string | null
  remote: boolean
}

export interface FormattedAddressItem {
  country: string | null
  addressLine: string | null
  postalCode: string | null
  city: string | null
  latitude?: string
  longitude?: string
  additionalInstructions?: string | null
  formattedAddress?: string
  label?: string
}
