export function convertirMetres(metres) {
  if (!metres) return ''
  const km = metres / 1000
  const decimales = 2 // Nombre de décimales à afficher

  if (km >= 1) {
    return km.toFixed(decimales) + ' km'
  } else {
    return metres.toFixed(0) + ' m'
  }
}
